@media screen and (max-width: 991px) {
  .contant-btn {
    margin-top: 2rem;
  }
  .dateSearch {
    flex-wrap: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 2rem;
  }
  .d-1 p {
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;
  }
  .input-date {
    width: 100%;
  }
}

@media screen and (max-width: 770px) {
  .w-full.table-respon {
    width: 768px;
  }
  .fc-1 {
    flex-direction: column;
    gap: 1rem;
  }
  .fc-1.poly {
    flex-direction: row;
  }
  .form-container.form-margin-top {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 770px) {
  .w-full.table-respon {
    width: 768px;
  }
  .fc-1 {
    flex-direction: column;
    gap: 1rem;
  }
  .fc-1.poly {
    flex-direction: row;
  }
  .form-container.form-margin-top {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 575px) {
  .relative.overflow-x-auto.margin-res {
    margin: 0 1rem;
  }
}

@media screen and (max-width: 490px) {
  .container-I .row {
    margin-left: 0;
    margin-right: 0;
  }
  .focus-text,
  .preiod-time span,
  .chart-selector span {
    font-size: 12px !important;
  }
}
