#board {
  overflow: hidden;
  padding-bottom: 70px;
  margin-right: 30px;
  margin-left: 30px;
}

.container-board {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.table-board {
  border-collapse: collapse;
  font-size: 16px;
  width: 100%;
  margin: 0;
  background-color: #d2d2d240;
}
.thead-board {
  background-color: var(--clr-blue);
  height: 3rem;
}

.tr-board {
  border-bottom: 1px solid #ddd;
}

.tr-board td {
  border-bottom: 1px solid #ddd;
}

.th-board {
  color: white;
  line-height: 3.5;
}

.td-board {
  text-align: center;
  color: var(--clr-blue);
  line-height: 3;
}

.box-line .t-h {
  font-size: 30px;
  line-height: 1.6;
  font-weight: 600;
  margin-bottom: 15px;
  color: #1b3a91;
}

.box-line .t-1 {
  font-size: 20px;
  line-height: 1.6;
  font-weight: 600;
  margin-bottom: 15px;
  color: #1b3a91;
}

.box-line .t-2 {
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 15px;
  color: #000000;
}
