/* @import "../../index.css"; */

.navigation-wrap {
  position: fixed;
  width: 84%;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: var(--bg-main-white);
}

.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 10px 0;
  box-shadow: var(--shadow-navbar);
  transition: all 0.3s ease-out;
}

.cover-menu {
  margin: 0 2.125rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-logo {
  display: inline-block;
  padding-top: 0.32421875rem;
  /* padding-bottom: 0.32421875rem; */
  width: 120px;
  min-width: 100px;
  margin-right: 1rem;
  /* white-space: nowrap; */
}

.navbar-logo img {
  /* height: 55px;
  width: auto;
  display: block;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; */

  height: 100%;
  width: 100%;
  object-fit: cover;
  /* display: block; */
  transition: all 0.3s ease-out;
}

.navbar-hamburger {
  display: none;
  position: relative;
  width: 30px;
  height: 30px;
  color: var(--clr-gray);
  cursor: pointer;
}

.navbar-hamburger svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navbar-collapse {
  display: flex;
  margin-left: auto;
  /* gap: 1rem; */
  /* flex-grow: 1; */
  /* justify-content: flex-end; */
}

.nav-item {
  position: relative;
  transition: all 200ms linear;
  list-style-type: none;
  cursor: pointer;
  margin-left: 1rem;
}

.nav-item::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 3px;
  content: "";
  background-color: #1b3b92;
  opacity: 0;
  transition: all 200ms linear;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: #212121;
}

.nav-link {
  font-size: 17px;
  font-weight: 400;
  position: relative;
  /* padding: 5px 0 !important; */
  padding: 5px 0;
  display: inline-block;
  cursor: pointer;
  color: var(--clr-black);
}

.text-bold {
  font-weight: 600;
  cursor: pointer;
}

.animated {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated-1 {
  -webkit-animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-duration: 0.1s;
  animation-fill-mode: both;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.nav-item:hover::after {
  bottom: -1px;
  opacity: 1;
}

.nav-link:hover {
  color: var(--clr-blue);
}

li.nav-item > ul.dropdown-menu {
  position: absolute;
  top: 36.5px; /*40px;*/
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0;
  margin: 0;
  font-size: 0.9375rem;
  color: #fff;
  text-align: left;
  list-style: none;
  background-color: #fff;
  /* background-clip: padding-box; */
  border: 0 solid #444;
  border-radius: 0;
  box-shadow: 0 0 10px 0 rgb(24 44 93/22%);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 9px 20px 9px 15px;
  clear: both;
  color: var(--clr-black);
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  margin-left: 0;
  font-size: 17px;
  font-weight: 400;
  transition: color 0.5s ease-out, background 0.6s ease-out;
}

.dropdown-item-sub {
  display: block;
  width: 100%;
  padding: 9px 20px 9px 15px;
  clear: both;
  color: var(--clr-black);
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  margin-left: 0;
  font-size: 17px;
  font-weight: 400;
  transition: color 0.5s ease-out, background 0.6s ease-out;
}

li.nav-item:hover > ul.dropdown-menu {
  display: block;
}

li:hover > .dropdown-item {
  background: var(--clr-blue);
  color: var(--clr-white);
}

.nav-item:hover > .nav-link {
  color: var(--clr-blue);
}

.not-events {
  pointer-events: none;
}

li:hover > ul.dropdown-item-sub.left {
  display: block;
}

/* .nav-link.no-padding {
  padding: 0;
} */

/* .nav-item:not(::after).nav-hover {
  margin: 0;
} */

.nav-item-nav-hover {
  position: relative;
  transition: all 200ms linear;
  list-style-type: none;
  cursor: pointer;
}

ul.dropdown-item-sub.left {
  position: absolute;
  top: 0%;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0;
  margin: 0;
  font-size: 0.9375rem;
  color: #fff;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border: 0 solid #444;
  border-radius: 0;
  display: none;

  width: auto;
  right: 100%;
  left: auto;
  border-width: 0 !important;
  box-shadow: -2px 0 7px 0 rgb(24 44 93/22%);
  background-color: #fff;
}

/* .nav-position-r {
  right: 100%;
  left: auto;
  border-width: 0 !important;
  box-shadow: -2px 0 7px 0 rgb(24 44 93/22%);
} */

/* a.dropdown-item.padding-left:hover {
  color: var(--clr-blue);
  background-color: var(--bg-main-white);
} */

/* li.dropdown-item:hover > ul.dropdown-item.left {
  right: 100%;
  width: 100%;
  left: auto;
  border-width: 0 !important;
  box-shadow: -2px 0 7px 0 rgb(24 44 93/22%);
  position: absolute;
  display: none;

  left: 100%;
  top: 0%;
  margin: 0 20px;
  border-width: 0;
} */

/* li:hover > .dropdown-item > ul.dropdown-item.left {
  display: block;
} */

.container-footer {
  display: block;
  position: relative;
  width: 100%;
  background: url(../assets/Bg/bg-footer.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 65px;
}

.footer-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.content-footer {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.content-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.tab-color-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  background-color: #bcbcbc;
}

.col-fluid {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.layout-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  height: 54px;
  align-items: center;

  max-width: 60%;
}

.text-fluid {
  font-size: 0.938rem;
  color: var(--clr-black);
  font-weight: 500;
}

.text-fluid a.a-cookies-policy {
  font-size: 0.938rem;
  color: var(--clr-black);
  font-weight: 500;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.content-footer-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-footer {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.footer-content {
  padding-bottom: 53px;
}

.col-25-footer {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-50-footer {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.footer-logo-container {
  margin-top: -14px;
}

.footer-about-text {
  margin-top: 25px;
}

.footer-logo-text {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 0.75;
  color: #fff;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.footer-logo-text img {
  width: 130px;
}

.footer-about-text p {
  color: var(--clr-black);
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6;
}

.main-footer-contact {
  padding-left: 36px;
}

.footer-contact-info {
  margin-top: 20px;
}

.footer-contact-info ul li a {
  font-size: 1rem;
  font-weight: 400;
  color: var(--clr-black);
}

.footer-contact-info ul li {
  margin: 5px 0;
}

.main-footer-link {
  padding-left: 80px;
}

/* ul {
  list-style-type: disc;
  list-style-position: inside;
} */

.footer-link ul.sub {
  columns: 1;
  -webkit-columns: 1;
  -moz-columns: 1;
  margin-top: 10px;
  padding-left: 10px;
  color: #565656;
  font-size: 1rem;
  margin: 15px 0; /* 10px */
}

.footer-link-container {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  margin-top: 20px;
}

.footer-link {
  display: flex;
}

.footer-link ul {
  /* columns: 2; */
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
}

.footer-link ul li:not(:last-child) {
  margin-bottom: 5px;
}

.footer-link ul li a {
  font-size: 1rem;
  font-weight: 400;
  color: #444;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
  /* margin: 10px 0; */
}

.footer-link ul li a:hover {
  color: var(--clr-black);
}

.footer-link ul.sub a {
  font-size: 1rem;
  font-weight: 400;
  color: #444;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
  /* margin: 10px 0; */

  list-style-type: disc;
  list-style-position: inside;
  display: list-item;
}

.footer-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--clr-black);
  line-height: 0.75;
}

ul.ul-test {
  list-style-type: disc;
  list-style-position: inside;
}

.text-fluid a.a-cookies-policy:hover {
  color: var(--clr-blue);
}

/* @media screen and (max-width: 1199px) {
  .nav-link,
  .dropdown-item {
    font-size: 1rem;
  }

  .nav-item {
    margin-left: 12px;
  }
} */

/* @media screen and (max-width: 1050px) {
  .nav-link,
  .dropdown-item {
    font-size: 15px;
  }
} */

/* @media screen and (max-width: 991px) {
  .nav-link,
  .dropdown-item {
    font-size: 15px;
  }

  .collapse:not(.show) {
    display: none;
  }

  .navbar-hamburger {
    display: block;
  }

  .navbar-mobile {
    flex-basis: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }

  li.nav-item > ul.dropdown-menu {
    top: 36.5px;
    left: 0;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0;
    margin: 0;
    font-size: 0.9375rem;
    color: #fff;
    text-align: left;
    list-style: none;
    background-color: none;
    position: static;
    float: none;
    box-shadow: none;
  }
} */
/* li.nav-item:active > ul.dropdown-menu {
    display: block;
  } 
  ---------- อยู่ใน 991 */

/* @media screen and (max-width: 575px) {
  .navigation-wrap {
    width: 94% !important;
  }
} */
