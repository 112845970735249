.bg-image-banner-ir {
  background: url(../assets/Bg/ir-banner-f.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.container-content-ir {
  background: url(../assets/Bg/bg-our-IR_news_calendar.png);
  background-position: left top;
  /* background-size: cover; */
  background-repeat: no-repeat;
  padding-bottom: 100px;
  /* padding-top: 100px; */
}

.container-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.cover-banner-other-content {
  margin-bottom: 50px;
  margin-top: -20px;
}

.cover-banner-other-content img {
  width: 100%;
}

.cover-our-ir {
  text-align: center;
  margin-bottom: 40px;
}

.cover-our-ir .t1 {
  font-size: 1rem;
  font-weight: 800;
  color: var(--clr-blue);
  margin-bottom: 25px;
}

.cover-our-ir .t2 {
  font-size: 2.25rem;
  font-weight: bold;
  color: #030303;
  margin-bottom: 44px !important;
  line-height: 1;
}

.content-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* margin-right: -15px;
  margin-left: -15px; */
}

.margin-button {
  margin-bottom: 2rem;
}

.main-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.layout-our-ir {
  background-color: var(--clr-white);
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 3px 20px 6px rgb(31 62 85 / 10%);
  -moz-box-shadow: 0px 3px 20px 6px rgb(31 62 85 / 10%);
  box-shadow: 0px 3px 20px 6px rgb(31 62 85 / 10%);
  border-radius: 6px;
  padding: 50px 15px 5px 30px;
  /* height: 205px; */
}

.img-our-ir-1:hover figure.effect-action p,
.img-our-ir-2:hover figure.effect-action p,
.img-our-ir-3:hover figure.effect-action p,
.img-our-ir-4:hover figure.effect-action p {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
}

.img-our-ir-1:hover figure.effect-action img,
.img-our-ir-1:hover figure.effect-action h2,
.img-our-ir-1:hover figure.effect-action p,
.img-our-ir-2:hover figure.effect-action img,
.img-our-ir-2:hover figure.effect-action h2,
.img-our-ir-2:hover figure.effect-action p,
.img-our-ir-3:hover figure.effect-action img,
.img-our-ir-3:hover figure.effect-action h2,
.img-our-ir-3:hover figure.effect-action p,
.img-our-ir-4:hover figure.effect-action img,
.img-our-ir-4:hover figure.effect-action h2,
.img-our-ir-4:hover figure.effect-action p {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.img-our-ir-1:hover figure.effect-action img,
.img-our-ir-1:hover figure.effect-action p,
.img-our-ir-2:hover figure.effect-action img,
.img-our-ir-2:hover figure.effect-action p,
.img-our-ir-3:hover figure.effect-action img,
.img-our-ir-3:hover figure.effect-action p,
.img-our-ir-4:hover figure.effect-action img,
.img-our-ir-4:hover figure.effect-action p {
  opacity: 1;
}

.layout-our-ir img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img-our-ir-1 {
  background: url(../assets/Image/shotcut-1.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.img-our-ir-2 {
  background: url(../assets/Image/shotcut-2.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.img-our-ir-3 {
  background: url(../assets/Image/shotcut-3.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.img-our-ir-4 {
  background: url(../assets/Image/shotcut-4.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

figure.effect-action figcaption {
  top: auto;
  bottom: 0;
  height: 50%;
  text-align: left;
}

figure.effect-action h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  color: var(--clr-white);
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 10px;
}

figure.effect-action p {
  font-size: 1.25rem;
  font-weight: normal;
  color: var(--clr-white);
  opacity: 0;
  -webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
  transition: opacity 0.2s, transform 0.35s;
}

figure.effect-action h2,
figure.effect-action p {
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0);
  text-shadow: 0 0 20px rgb(2 20 56 / 39%);
}

.container-main .ir-financial {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.cover-stock-price {
  background: url(../assets/Bg/bg-stock-price.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* padding-top: 100px; */
  height: 797px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5rem;
}

.main-ir-col {
  position: relative;
  width: 100%;
  /* padding-right: 15px;
  padding-left: 15px; */
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  /* text-align: center; */
}

.cover-financial-highlights {
  background: url(../assets/Bg/bg-financial-highlights.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  height: 797px;
}

.main-ir-col .t-1 {
  font-size: 2.875rem;
  font-weight: bold;
  text-align: center;
  color: var(--clr-blue);
  margin-bottom: -5px;
}

.main-ir-col .t-2 {
  font-size: 1.25rem;
  font-weight: normal;
  text-align: center;
  color: var(--clr-blue);
  margin-bottom: 3px;
}

.main-ir-col .t-3 {
  font-size: 0.875rem;
  font-weight: normal;
  text-align: center;
  color: var(--clr-blue);
}

.content-row-financial {
  display: flex;
  flex-wrap: wrap;
  padding: 0 5rem;
}

.head-financial .t-1 {
  font-size: 2.25rem;
  font-weight: bold;
  color: #030303;
  margin-bottom: 44px !important;
  line-height: 1;
  margin: 0;
}

.main-box-chart {
  margin: 10px 15% 0px;
}

.box-chart {
  background-color: var(--clr-white);
  /* border-radius: 10px; */
  /* margin: 0px 15% 0px 15%; */
  /* height: 667px; */
  /* margin-top: 10px; */
  height: 420px;
  padding: 0 20px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 3px 20px 6px rgb(31 62 85 / 8%);
  -moz-box-shadow: 0px 3px 20px 6px rgb(31 62 85 / 8%);
  box-shadow: 0px 3px 20px 6px rgb(31 62 85 / 8%);

  display: flex;
  justify-content: center;
}

.tablist-financial {
  display: flex;
  width: 100%;
  max-width: 80%;
  justify-content: center;
  margin: 2rem auto;
}

.button-yoy {
  width: 100%;
  border-radius: 10px 0 0 10px;
  border: 1px solid #ffffff00;
  background-color: #3e60bb;
  color: var(--clr-white);
  height: 46px;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 400;
}

.button-qoq {
  width: 100%;
  border-radius: 0 10px 10px 0;
  border: 1px solid #ffffff00;
  background-color: var(--clr-white);
  color: #bababa;
  height: 46px;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 400;
}

.button-tablist {
  width: 100%;
  border: 1px solid #ffffff00;
  background-color: var(--clr-white);
  color: #bababa;
  height: 50px;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 400;
}

.financial-left {
  border-radius: 10px 0 0 10px;
}

.financial-right {
  border-radius: 0 10px 10px 0;
}

.button-tablist.active {
  background-color: #3e60bb;
  color: var(--clr-white);
}

.layout-box-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 2rem 0;
}

.container-content-financial {
  background: url(../assets/Bg/bg-investor-kits.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 70px;
  height: auto;
}

.ir-head {
  font-size: 2.25rem;
  font-weight: bold;
  color: #030303;
  margin-bottom: 44px !important;
  line-height: 1;
}

.ir-sub {
  font-size: 1.125rem;
  font-weight: normal;
  color: #666666;
}

.main-col-kits {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.margin-top {
  margin-top: 2rem;
}

.col-25 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-75 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.cover-download-doc {
  background-color: var(--bg-main-white);
  -webkit-box-shadow: 0px 3px 20px 6px rgb(31 62 85 / 10%);
  -moz-box-shadow: 0px 3px 20px 6px rgb(31 62 85 / 10%);
  box-shadow: 0px 3px 20px 6px rgb(31 62 85 / 10%);
  border-radius: 6px;
  padding: 20px 20px 20px 20px;
  height: 593px;
  margin-right: 1rem;
}

.head-box-ir-kits {
  margin-bottom: 2rem;
}

.line-head-box-ir-kits {
  width: 30px;
  height: 2px;
  background-color: #3e60bb;
  float: left;
  margin-top: 9px;
  margin-right: 10px;
}

.head-box-ir-kits p {
  font-size: 0.938rem;
  font-weight: bold;
  color: #3e60bb;
  margin-bottom: 5px;
  margin-bottom: 30px;
}

.doc {
  border-bottom: 1px solid #e6e2eb;
  margin-bottom: 14px;
  padding-bottom: 12px;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.download-doc p {
  font-size: 0.938rem;
  font-weight: 600;
  color: #000000;
  margin-bottom: 2px;
}

.download-doc .t1 {
  font-size: 0.938rem;
  font-weight: bold;
  color: #3e60bb;
  transition: ease 0.5s;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.download-doc svg {
  width: 20px;
  height: 20px;
  fill: #3e60bb;
}

.content-row-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* margin-right: -15px;
  margin-left: -15px; */
}

.main-col-box-ir {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.cover-kits {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 3px 20px 6px rgb(31 62 85 / 10%);
  -moz-box-shadow: 0px 3px 20px 6px rgb(31 62 85 / 10%);
  box-shadow: 0px 3px 20px 6px rgb(31 62 85 / 10%);
  border-radius: 5px;
  padding: 20px 20px 15px 20px;
  margin-bottom: 30px;
  height: 281px;
}

.cover-kits.cover-kits-1 {
  background: url(../assets/Image/bg-IK-1.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: ease 0.5s;
}

.cover-kits.cover-kits-1:hover {
  background: url(../assets/Image/bg-IK-1-hover.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.cover-kits.cover-kits-2 {
  background: url(../assets/Image/bg-IK-2.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: ease 0.5s;
}

.cover-kits.cover-kits-2:hover {
  background: url(../assets/Image/bg-IK-2-hover.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.cover-kits.cover-kits-3 {
  background: url(../assets/Image/bg-IK-3.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: ease 0.5s;
}

.cover-kits.cover-kits-3:hover {
  background: url(../assets/Image/bg-IK-3-hover.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.cover-kits.cover-kits-4 {
  background: url(../assets/Image/bg-IK-4.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: ease 0.5s;
}

.cover-kits.cover-kits-4:hover {
  background: url(../assets/Image/bg-IK-4-hover.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.cover-kits.cover-kits-5 {
  background: url(../assets/Image/bg-IK-an.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: ease 0.5s;

  position: relative;
}

.kits-head .line {
  width: 30px;
  height: 2px;
  background-color: #ffffff;
  float: left;
  margin-top: 9px;
  margin-right: 10px;
}

.kits-head p {
  font-size: 0.938rem;
  font-weight: bold;
  color: var(--clr-white);
  margin-bottom: 50px;
}

.cover-kits .t1 {
  font-size: 1.875rem;
  font-weight: bold;
  color: var(--bg-main-white);
  line-height: 1.3;
  margin: 3.5rem 0;
}

.cover-kits .t2 {
  font-size: 0.938rem;
  font-weight: bold;
  color: var(--clr-white);
  transition: ease 0.5s;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.cover-kits svg {
  width: 20px;
  height: 20px;
  fill: var(--clr-white);
}

.text-ir-between {
  display: flex;
  align-content: space-between;
}

.test {
  position: relative;
  width: 50px;
  height: 50px;
  color: var(--clr-white);
}

.switch-seq {
  /* display: table; */
  flex-direction: row-reverse;
}

.t1.add-margin {
  margin: 4.5rem 0;
}

.large {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.6666666667%;
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
  position: relative;
}

.book-an-pic {
  position: absolute;
  bottom: 0;
  width: 29%;
}

.book-an-text {
  /* padding: 15px 15px 10px 50%; */

  padding: 0 15px 0 50%;
  position: relative;
  bottom: 20px;
}

.book-an-text .t1 {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--clr-white);
  margin-bottom: 10px;
}

.book-an-text .t2 {
  font-size: 1.063rem;
  font-weight: normal;
  color: var(--clr-white);
  margin-bottom: 15px;
}

.book-an-text .t3 {
  font-size: 0.938rem;
  font-weight: bold;
  color: var(--clr-white);
  transition: ease 0.5s;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  margin-right: 30px;
  float: left;
}

.book-an-text .read-more {
  float: none;
  float: unset;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 16px;
}

.container-content-email {
  background: url(../assets/Bg/bg-email-contact.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 130px;
  padding-bottom: 130px;
}

.col-66 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
}

.col-33 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
  display: flex;
}

.email-contact-text {
}

.email-contact-text .t1 {
  font-size: 2.25rem;
  font-weight: bold;
  color: var(--clr-white);
  margin-bottom: 1rem;
}

.email-contact-text .t2 {
  font-size: 1.125rem;
  font-weight: normal;
  color: var(--clr-white);
  margin-right: 8rem;
}

.email-contact-bt {
  display: inline-flex;
  /* margin-top: 40px; */
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.main-bt-email-contact {
  width: 100%;
}

.email-bt-1 {
  display: inline-block;
  position: relative;
  color: #3e60bb;
  background-color: #d4e3ee;
  font-size: 1.063rem;
  font-weight: 600;
  transition: ease 0.5s;
  text-align: center;
  border-radius: 10px;
  height: 60px;
  width: 100%;
}

.contact-bt-2 {
  display: inline-block;
  position: relative;
  color: var(--clr-white);
  background-color: #3e60bb;
  font-size: 17px;
  font-weight: 600;
  transition: ease 0.5s;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  height: 60px;
}

.email-bt-1:hover {
  background-color: #b0c1ce;
  color: #3e60bb;
  text-decoration: none;
}

.contact-bt-2:hover {
  background-color: #2c4b9e;
  color: #ffffff;
  text-decoration: none;
}

.main-chart {
  width: 100%;
  max-width: 582px;
  position: relative;
  height: 100%;
  margin: 0 auto;
}

.apex-charts-canvas {
  position: relative;
  user-select: none;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: auto;
}

.container-top {
  margin-top: 150px;
  overflow: hidden;
  padding-bottom: 70px;
}

.row-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-main {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.layout-text-head {
  border-right: 1px solid #d1d1d1;
  border-left: 1px solid #d1d1d1;
  padding: 10px 15px;
  margin: 40px 0 0;

  text-align: center !important;
}

.layout-text-head .t1 {
  font-size: 1.5rem;
  line-height: 0;
  font-weight: 600;
  color: var(--clr-gray);

  margin: 10px 0 35px 0;
}

.layout-text-head .t2 {
  font-size: 2.5rem;
  line-height: 1.1;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--clr-blue);
}

.container-not-data {
  margin-top: 60px;
}

.flex-main-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 230px;
}

.text-not-data {
  font-size: 1.25rem;
  color: var(--clr-gray);
  font-weight: 500;
}

.container-content-top {
  margin-top: 60px;
}

.col-50 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.cover-bg-content-report {
  background: url(../assets/Bg/bg-an.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #eaf3fa;
  margin-bottom: 30px;
  padding-bottom: 80px;
  padding-top: 80px;
  box-shadow: 0 3px 20px 6px rgb(31 62 85/10%);
  border-radius: 6px;
}

.cover-bg-content-report img {
  margin: 0 auto 0;
  display: block;
  width: 180px;
  box-shadow: 2px 0 7px rgb(0 0 0/40%);
  background-color: #fff;
}

.cover-bg-content-report .t1 {
  font-size: 1.375rem;
  font-weight: 700;
  color: #3e60bb;
  text-align: center;
  padding-top: 30px;
  margin-bottom: 1rem;
}

.cover-bg-content-report .t2 {
  width: 70%;
  text-align: center;
  margin: auto;
  margin-bottom: 30px;
  font-size: 1.125rem;
}

.cover-bt-report {
  width: 100%;
  max-width: 35%;
  margin: 1rem auto 0;
}

.img-circle-bg-1 {
  position: absolute;
  top: 50px;
  right: -10px;
  opacity: 0.5;
  z-index: -1;
}

.circle-bg-1 {
  position: absolute;
  bottom: -160px;
  left: -10px;
  height: 220px;
  width: 220px;
  background-color: #d1d8e96b;
  border-radius: 150px;
}

.layout-content-policy {
  display: flex;
  justify-content: flex-start;
  margin: 3rem 0;
  gap: 1rem;
  flex-direction: column;
}

.text-normal {
  font-size: 1.125rem;
  line-height: 1.6;
  font-weight: 400;
}

.hr-line {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.text-header {
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: 700;
  color: var(--clr-blue);
  margin-bottom: 10px;
}

.text-normal a:hover {
  color: #092267;
  text-decoration: none;
}

.text-sub-header {
  font-size: 1.125rem;
  line-height: 1.6;
  font-weight: 600;
  color: var(--clr-black);
}

/* stock-info */
.content-stock-info {
  margin: 56px 0;
  flex-direction: row;
  display: flex;
  height: auto;
  color: var(--clr-blue);
  box-shadow: 25px 25px 50px -1px rgba(2, 2, 100, 0.25),
    /* เงาขวาล่าง */ 25px 25px 50px -1px rgba(2, 2, 100, 0.25); /* เงาซ้ายบน */
  /* box-shadow: 25px 25px 50px -1px rgba(2, 2, 100, 0.25); */
  align-items: center;
}
.stock-info-left {
  color: var(--blue-900);
  flex-basis: 50%;
  background-color: var(--white);
  padding: 60px 40px;
  height: 100%;
  /* border-radius: 4px; */
}
.card-textInfo {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: flex-start;
}
.card-textInfo span {
  font-weight: 600;
}
.stock-info-right .card-textInfo {
  display: flex;
  justify-content: space-around;
}
.card-textInfo:first-child {
  margin-bottom: 40px; /* กำหนด margin 20px สำหรับคลาสแรก */
}

.card-textInfo:last-child {
  margin-bottom: 0px; /* กำหนด margin 40px สำหรับคลาสล่าง */
  margin-top: 30px;
}
.content-about-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-around;
  flex: 1;
}

.content-about-info svg {
  width: 25px;
  height: 25px;
}
.content-about-info p {
  margin: 0;
  color: var(--clr-blue);
  text-align: center;
  font-weight: 600;
}
.sharesInfo {
  font-size: 40px;
}

.card-content-2,
.card-content-3,
.card-content-4 {
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
}
.card-content-4 hr,
.stock-info-right hr {
  background-color: var(--clr-blue);
  width: 100%;
  height: 3px;
  margin-bottom: 20px;
}
.card-stock-textInfo {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 0.35rem;
  /* margin-top: 30px; */
}
.card-stock-textInfo p {
  margin: 0px !important;
  color: var(--clr-blue);

  /* min-width: 80px; */
}

.card-stock-textInfo span {
  text-align: right;
}
.stock-info-right {
  color: #fff;
  padding: 40px 40px;
  /* border-left: 3px solid var(--clr-blue); */
  border-height: 70%;

  /* padding-bottom: 20px; */
  flex-grow: 1;
  height: 100%;
  background-color: #c9d6f8;
  /* border-radius: 0 4px 4px 0; */
}
.stock-info-right .card-textInfo {
  display: flex;
  justify-content: space-around;
}

.stock-info-right,
.stock-info-left {
  width: 100%;
  /* box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25); */
  /* border-radius: 24px; */
}
.t-xs {
  font-size: 14px;
  line-height: var(--line-height-xs-s);
  transition: 0.6s all ease-in-out;
}

.t-s {
  font-size: 16px;
  line-height: var(--line-height-xs-s);
  transition: 0.6s all ease-in-out;
}
.t-xl {
  font-size: 25px;
  line-height: var(--line-height-xl-2xl);
  transition: 0.6s all ease-in-out;
}
