
#organization {
    overflow: hidden;
    padding-bottom: 70px;
    margin-right: 30px;
    margin-left: 30px;
}
.text-skn-standard.organization-t2 {
    margin-top: 40px;
    margin-bottom: 60px;
  }
.cover-organization {
    display: flex;
    overflow: hidden;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    cursor: -webkit-zoom-in;
    box-shadow: 0 0 17px 11px #0309190d;
    margin: auto;
}
  
.cover-organization:hover img{
    transform: scale(1.1);
}
  
.img-zoom {
    object-fit: cover;
}