#tv-attr-logo {
  display: none;
}

.stock-info {
  color: #0f1e36;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  transition: all 0.3s ease;
  overflow: hidden;
  border-bottom-right-radius: 20px;
}

.stock-info-box {
  overflow: hidden;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #fff;
}

.stock-header {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: #a9be73;
  padding: 30px;
  color: #fff;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.stock-header .stock-header-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.stock-name {
  font-size: 28px;
  font-weight: 300;
  color: #fff;
}

.stock-price {
  display: flex;
  gap: 30px;
  align-items: flex-end;
}

.price {
  font-size: 50px;
  letter-spacing: 1px;
  font-weight: 300;
  color: #fff;
}

.unit {
  font-size: 20px;
  margin-bottom: 10px;
  color: #fff;
}

.stock-change {
  display: flex;
  flex-direction: column;
  color: #fff;
}

.change {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.value {
  font-size: 25px;

  color: #fff;
  letter-spacing: 2px;
}

.updated-time {
  color: #fff;
  font-size: 15px;
  margin-top: 10px;
}

.price-stock.layout {
  display: flex;

  gap: 10px;
}

.stock-details {
  flex: 3;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  color: #1f4a7a;
  background-color: #ffff;
}

.bg-stock {
  background-color: #a9be7340;
  padding: 20px;
}

.detail-row {
  display: flex;
  gap: 40px;
  padding-bottom: 30px;
  padding-top: 10px;
  border-bottom: 1px solid #e9e9e9;
  letter-spacing: 1px;
}
.detail-row:last-child {
  border: none;
}

.detail-row .price-title {
  font-size: 16px;
  font-weight: semibold;
  color: #053859;
}
.price-stock {
  font-size: 16px;
  font-weight: bold;
}

.detail-text {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chart-select-layout {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  /* margin-bottom: 20px; */
  border-bottom: 1px solid #b0b0b0;
  margin-right: 75px;
}

.chart-select-year {
  display: flex;
  gap: 20px;
  align-items: center;
}

.chart-select-year button {
  /* padding: 10px 15px; */
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  border: none;
  border-bottom: 2px solid #ffffff;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
}

.chart-select-year button:hover {
  border-bottom: 2px solid #1b3c93;
  color: #1b3c93;
  transition: all 0.3s ease;
  font-weight: bold;
}

.chart-select-year button:focus {
  border-bottom: 2px solid #1b3c93;
  color: #1b3c93;
  transition: all 0.3s ease;
  font-weight: bold;
}

.chart-select-year button:active {
  border-bottom: 2px solid #a2c5e2;
  color: #1b3c93;
  transition: all 0.3s ease;
  font-weight: bold;
}

.chart-select-layout span {
  font-size: 14px;
}

.chart-select-year button span {
  font-size: 16px;
}

.focus-text {
  font-size: 14px;
  font-weight: 700;
}

.inv-link {
  position: absolute;
  top: 50px;
  right: 100px;
  z-index: 1000;
  font-size: 14px;
  font-weight: 500;
  color: #00000036;
}

@media (max-width: 992px) {
  .stock-info {
    gap: 70px;
  }
  .detail-text {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 725px) {
  .stock-info {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
  .detail-row {
    padding-bottom: 10px;
  }
  .chart-select-year button span {
    font-size: 12px;
  }
  .chart-select-year {
    gap: 10px;
  }
}

.preiod-time {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
}

.preiod-time span {
  font-size: 14px;
  font-weight: 700;
}

.chart-stock-section {
  margin-top: 30px;
  width: 100%;
  border: 1px solid #c9d6f8;
  border-radius: 1rem;
}

.chart-stock {
  background-color: #fff;
  padding-top: 10px;
  /* padding-right: 25px;
  padding-left: 25px; */
  margin: 0 25px;
  margin-right: 0;
  border-radius: 15px;
}

.chart-stock-head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding-right: 40px;
  margin-top: 10px;
}

.chart-selector {
  /* padding-left: 30px; */
  display: flex;
  align-items: center;
}
.radio-button {
  @apply h-[16px] w-[16px] border border-solid border-[#0059AB];
  appearance: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
}
.radio-selector {
  display: flex;
  align-items: center;
}
.radio-button:checked {
  @apply border-[#0059AB] bg-none;
  /* border-color: #007bff; */
  /* เปลี่ยนสี border เมื่อถูกเลือก */
}

.radio-button:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 11px;
  height: 11px;
  border-color: #0059ab;
  background-color: #0059ab; /* สีของจุดใน radio เมื่อถูกเลือก */
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.radio-button:focus {
  --tw-ring-color: white;
}
.chart-container {
  padding-right: 70px;
}
@media screen and (max-width: 640px) {
  .chart-selector {
    flex-direction: column;
    align-items: start;
    gap: 8px;
  }
  .radio-selector label {
    font-size: 14px !important;
  }
  .radio-button {
    width: 14px;
    height: 14px;
  }
  .radio-button:checked::before {
    width: 8.5px;
    height: 8.5px;
  }
}
@media screen and (max-width: 400px) {
  .chart-select-year button {
    padding-left: 2px;
    padding-right: 2px;
  }
}

@media (max-width: 725px) {
  .chart-stock-head {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
  .stock-header {
    width: 100%;
  }
  .stock-details {
    width: 100%;
  }
  .chart-selector {
    padding-left: 0px;
  }
  .preiod-time {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.chartlayout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
  width: 100%;
  height: 100%;
  max-width: 100%; /* เพิ่มเพื่อจำกัดขนาด */
  max-height: 100%; /* เพิ่มเพื่อจำกัดขนาด */
}

/* .chart-selector span {
  font-weight: bold;
  font-size: 14px;
  margin-right: 25px;
} */

.chart-selector button {
  padding: 10px 15px;
  border: 1px solid #ededed;
  border-bottom: 2px solid #ccc;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.chart-selector button.selected {
  border-bottom: 2px solid #1b3c93;
  color: #1b3c93;
  transition: all 0.3s ease;
}

.toolTip-main {
  background: #fff;
  border-radius: 2px;

  z-index: 1000;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 10px;
  border-top-left-radius: 5px;
}

.toolTip-layout {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 5px;
}

.toolTip-main-text.name {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.toolTip-main-text p {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.toolTip-main-text.name.cand {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.toolTip-main-text b {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.toolTip-main-text {
  font-size: 14px;
  font-weight: 500;
  align-items: baseline;
  display: flex;
  gap: 10px;
}

.border-1 {
  border-bottom: 1px solid #b4b4b4;
}

.toolTip-main .toolTip-date {
  padding-top: 5px;
}

.text-body {
  font-size: 16px;
  font-weight: 500;
}

.bg-stock-flex {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.border-hr {
  border: 1px solid #1e2864;
  width: 100%;
}

/* @media screen and (max-width: 1550px) {
  .miniChart {
    width: 94% !important;
  }
}
@media screen and (max-width: 1400px) {
  .miniChart {
    width: 93.5% !important;
  }
}
@media screen and (max-width: 1240px) {
  .chart {
    width: 97.5% !important;
  }
  .miniChart {
    width: 92.5% !important;
  }
}
@media screen and (max-width: 1140px) {
  .miniChart {
    width: 92% !important;
  }
}
@media screen and (max-width: 991px) {
  .chart {
    width: 97% !important;
  }
  .miniChart {
    width: 90% !important;
  }
}
@media screen and (max-width: 965px) {
  .miniChart {
    width: 89.5% !important;
  }
}
@media screen and (max-width: 900px) {
  .miniChart {
    width: 89% !important;
  }
}
@media screen and (max-width: 860px) {
  .chart {
    width: 96.4% !important;
  }
  .miniChart {
    width: 88% !important;
  }
}
@media screen and (max-width: 800px) {
  .miniChart {
    width: 87% !important;
  }
}
@media screen and (max-width: 750px) {
  .chart {
    width: 96% !important;
  }
  .miniChart {
    width: 86% !important;
  }
}
@media screen and (max-width: 700px) {
  .chart {
    width: 95% !important;
  }
  .miniChart {
    width: 85% !important;
  }
  .chart-selector {
    font-size: 13px;
  }
  .chart-stock-head {
    padding-left: 0;
  }
}
@media screen and (max-width: 660px) {
  .miniChart {
    width: 84% !important;
  }
}
@media screen and (max-width: 640px) {
  .miniChart {
    width: 82.5% !important;
  }
  .chart-selector {
    flex-direction: column;
    align-items: start;
    gap: 8px;
  }
}
@media screen and (max-width: 570px) {
  .miniChart {
    width: 84% !important;
  }
}
@media screen and (max-width: 550px) {
  .chart {
    width: 94% !important;
  }
}

@media screen and (max-width: 500px) {
  .miniChart {
    width: 82% !important;
  }
}
@media screen and (max-width: 470px) {
  .chart-stock-section {
    padding-bottom: 20px !important;
  }
}
@media screen and (max-width: 450px) {
  .miniChart {
    width: 80% !important;
  }
}
@media screen and (max-width: 420px) {
  .chart {
    width: 92.5% !important;
  }
  .miniChart {
    width: 78% !important;
  }
}
@media screen and (max-width: 380px) {
  .chart {
    width: 91% !important;
  }
  .miniChart {
    width: 75% !important;
  }
} */
