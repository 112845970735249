@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "db_heaventmed";
  src: url(/src/assets/Font/db_heavent_thin_v3.2-webfont.ttf);
}
@font-face {
  font-family: "db_heaventmed-bold";
  src: url(/src/assets/Font/DB\ Heavent\ Bold\ Cond.ttf);
}

:root {
  /*----- Font -----*/
  --f-db: "db_heaventmed";
  --f-db-b: "db_heaventmed-bold";
}

.collapse {
  visibility: inherit;
}
/* font-family: var(--f-db); */
body {
  margin: 0;
  font-family: "Noto Sans Thai";
}

.primaryText {
  /* font-family: 'Prompt', sans-serif; */
  font-size: 17px;
  color: #565656;
  font-weight: 300;
  line-height: 1.6;
}

.secondaryText {
  font-size: 19px;
  line-height: 1.6;
}

.pText {
  font-size: 16px;
  line-height: 1.6;
}

.p1 {
  letter-spacing: 1.2px;
  color: #b3b3b3;
  font-weight: 600;
  font-size: 24px;
  font-family: "Noto Sans Thai";
}

.menuTextbox {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
}

@media (min-width: 640px) {
  .primaryText {
    font-size: 18px;
  }
}

/* Corporate */
.corporate {
  overflow: hidden;
  padding-bottom: 70px;
}
.text-skn-standard.corporate-t1 {
  text-align: left;
  font-size: 18px;
}
.corporate-dot-1 {
  position: absolute;
  top: 300px;
  right: -0px;
  opacity: 0.5;
  z-index: -1;
}
.corporate-dot-2 {
  position: absolute;
  bottom: -37px;
  left: -90px;
  height: 220px;
  width: 220px;
  background-color: #d1d8e96b;
  border-radius: 150px;
  z-index: -1;
}

.corporate-pic {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
  box-shadow: 0px 3px 20px 6px rgb(31 62 85 / 10%);
  border-radius: 6px;
}
.text-skn-standard.corporate-t2 {
  font-size: 26px;
  line-height: 1.6;
  font-weight: 800;
  color: #1c3c93;
  text-align: left;
  margin-bottom: 15px;
  margin-top: 60px;
}
.icon-corporate {
  width: 60px;
  display: block;
  margin: 40px auto 10px auto;
}
.text-skn-standard.corporate-t1.t3 {
  text-align: left;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 800;
  color: #1c3c93;
  margin-bottom: 7px;
  padding-left: 30px;
  margin-top: 13px;
}
.text-skn-standard.corporate-t1.t4 {
  text-align: left;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 800;
  color: #1c3c93;
  margin-bottom: 7px;
  padding-left: 0px;
  margin-top: 13px;
}
.text-t4 {
  list-style: decimal;
  color: #1c3c93;
  font-size: 18px;
  font-weight: 800;
  padding-left: 45px;
}

@media (max-width: 1199px) {
  .text-skn-standard.corporate-t1 br {
    display: none;
  }
  .text-skn-standard.corporate-t2 br {
    display: none;
  }
}
@media (max-width: 991px) {
  .text-skn-standard.corporate-t1.t3 {
    padding-left: 15px;
  }
  .text-t4 {
    padding-left: 30px;
  }
}
/* End Corporate */
