@import url('https://fonts.googleapis.com/css2?family=Inter&family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url(./CSS/Ir.css);
@import url(./CSS/Component.css);
@import url(./CSS/Layout.css);
@import url(./CSS/ResponsiveD.css);
@import url(./Components/Chart/Chart.css);

@font-face {
  font-family: 'db_heaventmed';
  src: url(/src/assets/Font/db_heavent_thin_v3.2-webfont.ttf);
}
@font-face {
  font-family: 'db_heaventmed-bold';
  src: url(/src/assets/Font/DB\ Heavent\ Bold\ Cond.ttf);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans Thai';
  /* color: var(--clr-black); */
}

:root {
  /*----- Color -----*/
  --clr-blue: #1b3b92;
  --clr-black: #000;
  --clr-black-gray: #565656;
  --clr-gray: #b3b3b3;
  --clr-white: #fff;
  --clr-brown: #6e4d25;

  /*----- Bg -----*/
  --bg-main-white: #fff;
  --bg-card-gray: #e2e2e2;
  --bg-card-white-fade: rgb(214 214 214/91%);
  --bg-circle-blue-fade: #d1d8e9;
  --bg-circle-gray-fade: rgb(204 204 204/65%);

  /*----- Line -----*/
  --line-clr-gray: #d1d1d1;

  /*----- Shadow -----*/
  --shadow-navbar: 0 0 10px 0 rgb(24 44 93/22%);

  /*----- Font Size : Banner -----*/
  --f-h1-banner-85: 85px;
  --f-h2-banner-34: 34px;
  --f-h3-banner-18: 18px;

  /*----- Font Size : Navber -----*/
  --f-navbar-17: 17px;

  /*----- Font -----*/
  --f-db: 'db_heaventmed';
  --f-db-b: 'db_heaventmed-bold';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-d {
  /* width: 100vw; */
  height: 100%;
  min-height: 100vh;
  position: relative;
  background-color: var(--bg-main-white);
}

.container-page {
  height: 100%;
  position: relative;
  background-color: var(--bg-main-white);
}

button,
input {
  overflow: visible;
}

input:focus,
textarea:focus {
  outline: 0 dotted;
  outline: 0 auto -webkit-focus-ring-color;
}

textarea {
  overflow: auto;
  resize: vertical;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input[type='radio'],
input[type='checkbox'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

img {
  vertical-align: middle;
  border-style: none;
}

figure {
  margin: 1.2em 0px 1.2em 0px;
}

figcaption,
figure,
main {
  display: block;
}

p:last-of-type {
  margin-bottom: 0;
}

p {
  color: var(--clr-black);
}

.black {
  color: var(--clr-black);
}

.black-gray {
  color: #444;
}

strong {
  color: var(--clr-blue);
}

.bg-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0000006b;
  z-index: 9999;
  overflow: hidden;
}

.layout-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.card-popup {
  max-width: 1000px;
  position: relative;
  margin: 1rem;
}

.close-popup {
  position: absolute;
  padding: 1rem;
  top: 0;
  right: 0;
}

.icon-close-popup {
  color: white;
  cursor: pointer;
  transition: all 0.5s;
}

.icon-close-popup:hover {
  color: #000;
}
