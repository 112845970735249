.box-line-c {
  position: relative;
  width: 100%;
  margin: 0 20px 0 20px;
}
.form-container {
  display: flex;
  margin: 20px 0px 15px 0px;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
}

.form-container.form-margin-top {
  margin-top: 5rem;
}

.form-control {
  color: #080808;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  height: calc(2.15625rem + 16px);
  width: 100%;
}

.fc-1 {
  display: flex;
  padding: 0px 0px 0px 0px;
  justify-content: center;
}
.fc-1-1 {
  display: flex;
  padding: 0;
  justify-content: center;
}

.fc-1-1-edit {
  display: flex;
  padding: 0;
  justify-content: center;
  margin: 3rem 0 3rem;
}

.poly {
  align-items: center;
  gap: 10px;
}
.poly-text {
  margin: 3px 3px;
  color: var(--clr-blue);
}

.fc-11 {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  color: #a5a5a5;
}

.fc-11-bold {
  flex: 1;
  font-size: 18px;
  font-weight: 400;
  color: #a5a5a5;
  font-weight: bold;
}

.fc-12 {
  flex: 2;
  width: 100%;
}

.text-under-box {
  font-size: 16px;
  margin: 0px 0px 0px 0px;
  color: #a5a5a5;
}
.check-b {
  background-color: #fff;
  margin: 0;
}

.check-b:hover {
  background-color: #185abcbd;
  transition: 0.2s;
}
.fc-1-btn {
  /* display: flex;
  justify-content: center; */
  background-color: #1b3a91;
  color: #fff;
  /* padding: 13px 30px; */
  font-size: 17px;
  /* margin: 0; */
  font-weight: 400;
  letter-spacing: 0.5;
  transition: 0.2s;
  width: 200px;
  height: 50px;
  text-align: center;
  border-color: transparent;
  border-radius: 0.2rem;
}
.text-check-b {
  font-size: 16px;
  margin: 0;
}

/* @media (min-width:375px){
  .form-control{
    margin-left: 16px;
    width: 70%;
    height: ;
  }
} */
