.container-email-req {
  display: flex;
  margin: 20px 80px 15px 80px;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
}

.email-box {
  display: flex;
  padding: 0px 15rem 0px 15rem;
  flex-direction: column;
}

.em-t1 {
  /* flex: 1; */
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}
.em-b1 {
  flex: 1;
  font-size: 18px;
  font-weight: 400;
}

.poly-re {
  flex-direction: row;
  gap: 10px;
  margin-top: 30px;
}
.em-t1-poly {
  flex: 1;
  font-size: 18px;
  font-weight: 400;
  text-align: start;
}

.fc-1-2 {
  display: flex;
  padding: 30px 0px 20px 0px;
  justify-content: center;
}
