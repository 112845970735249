@import url(./indexD.css);
@import url(./CSS/IngCss/organization.css);
@import url(./CSS/IngCss/chairman.css);
@import url(./CSS/IngCss/vision.css);
@import url(./CSS/IngCss/board.css);
@import url(./CSS/IngCss/award.css);
@import url(./CSS/IngCss/newActivities.css);
@import url(./CSS/IngCss/update.css);
@import url(./CSS/IngCss/public.css);
@import url(./CSS/IngCss/relationDetail.css);
@import url(./CSS/IngCss/newsClipping.css);
@import url(./CSS/IngCss/complaints.css);
@import url(./CSS/IngCss/email.css);
@import url(./CSS/IngCss/majorSh.css);
@import url(./CSS/IngCss/irCalendar.css);
@import url(./CSS//IngCss/responsive.css);
@import url(./CSS/IngCss/generalMeeting.css);
#aboutUs {
  overflow: hidden;
}

.container-I {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.sub-top-hight {
  margin-top: 150px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  /* margin-right: 100px; */
  /* margin-left: 100px; */
}

.page-header p {
  font-size: 26px;
  line-height: 1.6;
  font-weight: 600;
  margin: 80px 0 30px 0;
  color: #1b3a91;
}

.page-header2 p {
  font-size: 33px;
  line-height: 1.6;
  font-weight: 700;
  margin: 80px 0 20px 0;
  color: #1b3a91;
}

.box-line {
  position: relative;
  width: 100%;
  margin-top: 50px;
}

.box-line p {
  font-size: 30px;
  line-height: 1.1;
  font-weight: 600;
  margin-bottom: 15px;
  color: #1b3a91;
}

.box-line-2 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: auto;
}

.text-center {
  text-align: center;
}

.text-skn-standard {
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
}

.about-text-cover {
  border-right: 1px solid #d1d1d1;
  border-left: 1px solid #d1d1d1;
  padding: 10px 18%;
  margin: 40px 0;
}
.about-text-cover .t-1 {
  font-size: 24px;
  line-height: 0;
  font-weight: 600;
  color: #b3b3b3;
  margin: 10px 0 35px 0;
}
.about-text-cover .t-2 {
  font-size: 40px;
  line-height: 1.1;
  font-weight: 600;
  margin-bottom: 15px;
  color: #1b3a91;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-box {
  display: flex;
  justify-content: center;
  margin: 1.5rem 0;
}
.page-number input,
.number-btn input,
input.prv-btn,
input.next-btn {
  border: 1px solid #3e60bb;
  background-color: var(--white);
  height: 35px;
  font-size: 14px;
  cursor: pointer;
  padding: 0 10px;
}
.page-number input.active {
  background-color: #1b3a91;
  color: #d1d1d1;
}

input.prv-btn,
input.next-btn {
  margin: 0 4px;
}
.page-number input,
.number-btn input,
input.prv-btn,
input.next-btn {
  border: 1px #3e60bb;
  background-color: var(--white);
  height: 35px;
  font-size: 14px;
  cursor: pointer;
  padding: 0 10px;
}
input.prv-btn,
input.next-btn {
  margin: 0 4px;
}

input.prv-btn,
input.next-btn {
  border: 1px #3e60bb;
  border-radius: 5px;
  background-color: #7387bf20;
  height: 35px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  padding: 0 10px;
}
.disabled {
  pointer-events: none;
  background-color: #d1d1d1;
  font-weight: medium;
  color: #b0b0b0;
}
.loader {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #1b3b92 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, #1b3b92);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}

.tabbed {
  text-indent: 2em; /* กำหนดความยาวของ tab ที่ต้องการ */
  padding-bottom: 1rem;
}
