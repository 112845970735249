.dateSearch {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 5rem;
  font-size: 20px;
  color: #a5a5a5;
  /* flex-direction: column;
  justify-content: center;
  margin: 0 2rem; */

}
.d-1 {
  display: flex;
 
  align-items: center;
  gap: 2rem;
  justify-content: center;
}


.input-date {
  height: 30px;
  align-items: center;
  /* height: 30px; */
  align-items: center;
  border: 1px solid var(--clr-gray);
  border-radius: 10px;
  height: 50px;
  padding: 0 1rem;
}

[type="button"].btn-1 {
  align-items: center;
  border-radius: 10px;
  border-color: transparent;
  width: 120px;
  height: 40px;
  background-color: #3578e5;
  color: white;
}
