.container-Detail {
  width: 100%;
  padding: 0px 0px;
  margin-right: auto;
  margin-left: auto;
}
.h-bg {
  background-image: url(../../assets/Bg/bg-read-detail.jpg);
  background-size: cover;
}
.f-detail {
  display: flex;
  padding: 20px 40px 20px 40px;
}

.logo-Dbar {
  width: 120px;
}
.pic-RDetail {
  width: 40%;
  margin: auto;
}

.cover-board-D {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 60px 40px 100px 40px;
  gap: 20px;
}
.cover-board-D b {
  color: #000066;
}
.text-d {
  padding: 0px 200px 0px 200px;
}
.contact-RDetail {
  display: flex;
  flex-direction: column;
  padding: 0px 200px 0px 200px;
}

.contact-RDetail p {
  margin: 16px 3px 0px 0px;
}

.linkD {
  display: flex;
}
