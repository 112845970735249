.no-information {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
}

.no-information p {
  text-align: center;
  color: #a5a5a5;
  font-size: 20px;
}
