.container-financial {
  margin: auto;
  margin-top: 40px;
  /* background-color: var(--clr-white);
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.25); */
  border-radius: 4px;
  color: var(--clr-white);
  height: auto;
}

.container-financial {
  display: flex;
  justify-content: center;
  /* box-shadow: 7px 8px 8px 7px rgb(0 0 0 / 25%); */
}

.container-select-financial {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  width: 96%;
}

.column-fn-1 {
  padding: 60px 10px;
  max-width: 280px;
  width: auto;
}

.text-p-span p {
  color: var(--clr-white);
  /* color: var(--clr-blue); */

  padding-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
}
.text-p-span span {
  color: var(--clr-white);
  /* color: var(--clr-blue); */
}
.hexagon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 300px;
  background-color: var(--clr-blue);
  border-radius: 20px;
  box-shadow: 7px 8px 8px 7px rgb(0 0 0 / 25%);
  padding: 16px;
  color: var(--clr-blue);
  padding-bottom: 0;
  text-align: center;

  transition: opacity 0.5s;
  cursor: pointer;
}

.hexagon p {
  margin: 0;
}

.hexagon:hover {
  /* opacity: 0.75; */
  background-color: var(--clr-white);
  color: var(--clr-blue);
  border: 3px solid var(--clr-blue);
}
.hexagon:hover .text-p-span p,
.hexagon:hover .text-p-span span {
  color: var(--clr-blue);
}

.hexagon:hover .btn-fn {
  background-color: var(--clr-blue);
  color: var(--clr-white);
}
/* .hexagon {
  height: 250px;
  width: 65%;
  background: var(--clr-blue);
  position: relative;
  left: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: var(--clr-blue);
  box-shadow: 8px 8px 7px rgb(0 0 0 / 25%);
  color: var(--clr-blue);
  text-align: center;
  opacity: 0.85;
  transition: opacity 0.5s;
  cursor: pointer;
}
.hexagon::before,
.hexagon::after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  top: 0;
  border-top: 125px solid transparent;
  border-bottom: 125px solid transparent;
}
.hexagon::before {
  left: -50px;
  border-right: 50px solid var(--clr-blue);
}
.hexagon::after {
  right: -50px;
  border-left: 50px solid var(--clr-blue);
} */

/* .column-fn-1 .hexagon {
  position: relative;
  width: 100px;
  height: 57.74px;
  background-color: #64c7cc;
  margin: 28.87px 0;
  display: inline-block;
  text-align: center;
  color: white;
  font-size: 14px;
  line-height: 57.74px;
}

.column-fn-1 .hexagon:before,
.column-fn-1 .hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
}

.column-fn-1 .hexagon:before {
  bottom: 100%;
  border-bottom: 28.87px solid #64c7cc;
}

.column-fn-1 .hexagon:after {
  top: 100%;
  border-top: 28.87px solid #64c7cc;
} */

/* .rhex {
  position: relative;
  margin: 1em auto;
  width: 10em;
  height: 17.32em;
  border-radius: 1em/ 0.5em;
  opacity: 0.25;
  background: orange;
  transition: opacity 0.5s;
  cursor: pointer;
} */
/* .rhex {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: var(--clr-blue);
  box-shadow: 8px 8px 7px rgb(0 0 0 / 25%);
  padding: 16px;
  color: var(--clr-blue);
  padding-bottom: 0;
  text-align: center;
  position: relative;
  margin: 1em auto;
  height: 250px;
  width: 60%;
  opacity: 0.85;
  transition: opacity 0.5s;
  cursor: pointer;
}
.rhex:before,
.rhex:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  top: 0;
  border-top: 125px solid transparent;
  border-bottom: 125px solid transparent;
}
.rhex:before {
  left: -50px;
  border-right: 50px solid var(--clr-blue);
}
.rhex:after {
  right: -50px;
  border-left: 50px solid var(--clr-blue);
}
.rhex:hover {
  opacity: 1;
} */

.select-year-fn {
  margin-right: 30px;
}
.box-fn {
  display: flex;
  flex-direction: column;
}

.btn-fn {
  width: 65%;
  height: 50px;
  bottom: 0;
  background-color: var(--clr-white);
  color: var(--clr-blue);
}

.finac-select select {
  border: 1px solid #000;
}

table.color_3 td {
  border: 1.5px solid#E6E6E6;
}

#ui-datepicker-div.booked_custom_date_picker
  table.ui-datepicker-calendar
  tbody
  td
  a.ui-state-active,
#ui-datepicker-div.booked_custom_date_picker
  table.ui-datepicker-calendar
  tbody
  td
  a.ui-state-active:hover,
body #booked-profile-page input[type="submit"].button-primary:hover,
body .booked-list-view button.button:hover,
body .booked-list-view input[type="submit"].button-primary:hover,
body table.booked-calendar input[type="submit"].button-primary:hover,
body .booked-modal input[type="submit"].button-primary:hover,
body table.booked-calendar th,
body table.booked-calendar thead,
body table.booked-calendar thead th,
body
  table.booked-calendar
  .booked-appt-list
  .timeslot
  .timeslot-people
  button:hover,
body #booked-profile-page .booked-profile-header,
body #booked-profile-page .booked-tabs li.active a,
body #booked-profile-page .booked-tabs li.active a:hover,
body #booked-profile-page .appt-block .google-cal-button > a:hover,
#ui-datepicker-div.booked_custom_date_picker .ui-datepicker-header {
  background: #c4f2d4 !important;
}
body #booked-profile-page input[type="submit"].button-primary:hover,
body table.booked-calendar input[type="submit"].button-primary:hover,
body .booked-list-view button.button:hover,
body .booked-list-view input[type="submit"].button-primary:hover,
body .booked-modal input[type="submit"].button-primary:hover,
body table.booked-calendar th,
body
  table.booked-calendar
  .booked-appt-list
  .timeslot
  .timeslot-people
  button:hover,
body #booked-profile-page .booked-profile-header,
body #booked-profile-page .appt-block .google-cal-button > a:hover {
  border-color: #c4f2d4 !important;
}
body table.booked-calendar tr.days,
body table.booked-calendar tr.days th,
body .booked-calendarSwitcher.calendar,
body #booked-profile-page .booked-tabs,
#ui-datepicker-div.booked_custom_date_picker table.ui-datepicker-calendar thead,
#ui-datepicker-div.booked_custom_date_picker
  table.ui-datepicker-calendar
  thead
  th {
  background: #039146 !important;
}
body table.booked-calendar tr.days th,
body #booked-profile-page .booked-tabs {
  border-color: #039146 !important;
}
#ui-datepicker-div.booked_custom_date_picker table.ui-datepicker-calendar tbody td.ui-datepicker-today a,#ui-datepicker-div.booked_custom_date_picker table.ui-datepicker-calendar tbody td.ui-datepicker-today a:hover,body #booked-profile-page input[type=submit].button-primary,body table.booked-calendar input[type=submit].button-primary,body .booked-list-view button.button, body .booked-list-view input[type=submit].button-primary,body .booked-list-view button.button, body .booked-list-view input[type=submit].button-primary,body .booked-modal input[type=submit].button-primary,body table.booked-calendar .booked-appt-list .timeslot .timeslot-people button,body #booked-profile-page .booked-profile-appt-list .appt-block.approved .status-block,body #booked-profile-page .appt-block .google-cal-button > a,body .booked-modal p.booked-title-bar,body table.booked-calendar td:hover .date span,body .booked-list-view a.booked_list_date_picker_trigger.booked-dp-active,body .booked-list-view a.booked_list_date_picker_trigger.booked-dp-active:hover,.booked-ms-modal .booked-book-appt 
/* Multi-Slot Booking */ {
  background: #56c477;
}
body #booked-profile-page input[type="submit"].button-primary,
body table.booked-calendar input[type="submit"].button-primary,
body .booked-list-view button.button,
body .booked-list-view input[type="submit"].button-primary,
body .booked-list-view button.button,
body .booked-list-view input[type="submit"].button-primary,
body .booked-modal input[type="submit"].button-primary,
body #booked-profile-page .appt-block .google-cal-button > a,
body table.booked-calendar .booked-appt-list .timeslot .timeslot-people button,
body .booked-list-view a.booked_list_date_picker_trigger.booked-dp-active,
body
  .booked-list-view
  a.booked_list_date_picker_trigger.booked-dp-active:hover {
  border-color: #56c477;
}
body .booked-modal .bm-window p i.fa,
body .booked-modal .bm-window a,
body .booked-appt-list .booked-public-appointment-title,
body .booked-modal .bm-window p.appointment-title,
.booked-ms-modal.visible:hover .booked-book-appt {
  color: #56c477;
}
.booked-appt-list .timeslot.has-title .booked-public-appointment-title {
  color: inherit;
}

.stm-header__row.stm-header__row_top ul li:nth-child(3):lang(en-US) {
  display: none;
}

.stm-header__row.stm-header__row_top ul li:nth-child(4):lang(th) {
  display: none;
}

.wpb_animate_when_almost_visible {
  opacity: 1;
}
/* ---------------------- email alert & inquiry ---------------------- */
.font_contact_ir {
  color: #ee2d35;
}
.font_email {
  color: #ee2d35;
}
.font_email2 {
  color: #ee2d35;
}
.line_dashed {
  width: 100%;
  border-bottom: 1px dashed #666666;
  padding: 0px 0px 10px 0px;
}
.bg_email_alert {
  background-color: #ececec;
}
.bg_email_alert2 {
  background-color: #ffffff;
}
.bg_email_alert3 {
  background-color: #ececec;
  width: 1px;
}
.bg_email_alert4 {
  background-color: #ececec; /*background:url(images/space2.png);*/
}
.bg_email_alert5 {
  background-color: #fbfbfb; /*background:url(images/space2.png);*/
}
.bg_email_alert6 {
}
.bg_email_alert7 {
}

.bgcolor_Inquiry1 {
  background-color: #eeeeee;
  cursor: pointer;
}
.bgcolor_Inquiry2 {
  background-color: #eeeeee;
  cursor: pointer;
}
.bgcolor_Inquiry1:hover {
  /*background-color: #FFF2F2;*/
  background-color: #fbfbfb;
  cursor: pointer;
}
.bgcolor_Inquiry2:hover {
  background-color: #fbfbfb;
  cursor: pointer;
}
.bgcolor_Inquiry4 {
  background-color: #eaf2ef;
}

.bgcolor_InquiryTap {
  background-color: #ececec;
  height: 50px;
  text-align: center;
}
.bgcolor_InquiryTap2 {
  background-color: #2196f3;
  height: 50px;
  font-weight: bold;
  text-align: center;
}
.bgcolor_InquiryTap3 {
  background-color: #ececec;
  height: 50px;
  font-weight: bold;
  text-align: center;
}

.bg_sign_in1 {
  background-color: #ffffff;
}
.bg_sign_in2 {
  background-color: #fbfbfb;
}
.bg_sign_in3 {
  background-color: #ffffff;
}

.bg_in_quiry1 {
  background-color: #fddbdd;
}
.bg_in_quiry2 {
  background-color: #ffffff;
}
.bg_in_quiry3 {
  background-color: #ececec;
}
.bg_in_quiry4 {
  background-color: #ececec;
}
.bg_in_quiry5 {
  background-color: #fbfbfb;
}
.bg_in_quiry6 {
  background-color: #fefcec;
}
.bg_in_quiry7 {
  background-color: #ececec;
}
.bg_in_quiry8 {
  background-color: #fbfbfb;
}
.bg_in_quiry9 {
  background-color: #ececec;
}

.font_Inquiry3 {
  color: #333;
  font-weight: bold;
}

.link_Inquiry {
  color: #333;
  font-weight: bold;
}
.link_Inquiry:hover {
  color: #c2161f;
  font-weight: bold;
}

.font_inquiry {
  color: #666;
  text-decoration: none;
  font-weight: bold;
}
.font_inquiry2 {
  color: #333;
  text-decoration: none; /*font-weight: bold; */
}
.font_inquiry3 {
  color: #333;
}
.font_inquiry4 {
  color: #666;
  text-decoration: none;
  font-weight: bold;
}

.font_datetime2 {
  /* color: #C2161F; */
  color: #666;
  font-size: 12px;
  /* font-weight: bold; */
  text-decoration: none;
}

.fi_head_table {
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  font-family: Tahoma, Geneva, sans-serif;
}
.style_finance_group {
  color: #444;
  font-size: 13px;
  font-weight: bold;
  font-family: Tahoma, Geneva, sans-serif;
  vertical-align: middle;
}
.fontcolor_finance_value {
  color: #666;
  font-size: 13px;
  font-family: Tahoma, Geneva, sans-serif;
}
.font_style_end_row {
  font-size: 13px;
  font-family: Tahoma, Geneva, sans-serif;
  color: #ffffff;
  line-height: 2; /*font-weight:bold;*/
}

.color_1 {
  line-height: 2;
  background-color: #03a9f4 !important;
}
.color_2 {
  line-height: 2;
  border: 1px solid #e9e9e9;
  background-color: #f9f9f9;
}
.color_3 {
  background-color: #c9c9c9;
}
.color_4 {
  background: #03a9f4;
  height: 35px;
}
.color_5 {
  background-color: #e6e6e6;
  line-height: 2;
}
.color_6 {
  background-color: #c649ba;
}
.color_7 {
  background-color: #000000;
}
.color_8 {
  background-color: #666555;
}
.color_9 {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  background-color: #fff;
  padding: 15px;
}

.download_fi_bg_color1 {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}
.download_fi_bg_color2 {
  /*background-color:#2B63A2;*/
}
.download_fi_bg_color3 {
}
.download_fi_bg_color4 {
}
.download_fi_font1 {
  color: #aa0000;
  font-weight: bold;
}

a.finance:link {
  font: 13px Tahoma, Geneva, sans-serif;
  color: #666;
  text-decoration: none;
  font-weight: bold;
}
a.finance:visited {
  font: 13px Tahoma, Geneva, sans-serif;
  color: #666;
  text-decoration: none;
  font-weight: bold;
}
a.finance:hover {
  font: 13px Tahoma, Geneva, sans-serif;
  color: #db1a15;
  text-decoration: none;
  font-weight: bold;
}
a.finance:active {
  font: 13px Tahoma, Geneva, sans-serif;
  color: #666;
  text-decoration: none;
  font-weight: bold;
}

a.fi_year:link {
  font: 13px Tahoma, Geneva, sans-serif;
  color: #666;
  text-decoration: none;
  font-weight: bold;
}
a.fi_year:visited {
  font: 13px Tahoma, Geneva, sans-serif;
  color: #666;
  text-decoration: none;
  font-weight: bold;
}
a.fi_year:hover {
  font: 13px Tahoma, Geneva, sans-serif;
  color: #db1a15;
  text-decoration: none;
  font-weight: bold;
}
a.fi_year:active {
  font: 13px Tahoma, Geneva, sans-serif;
  color: #666;
  text-decoration: none;
  font-weight: bold;
}
/*News for financial and financial yearly*/
.bgcolorfinancialdetail {
  padding-bottom: 0px; /*BACKGROUND: #FFFFFF;*/
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.fontcolor_finance_value1 {
  color: #666;
  font-size: 13px;
  font-family: Tahoma, Geneva, sans-serif;
}
.yearly_bg_color1 {
  background: #2196f3;
  height: 35px;
}
.yearly_bg_color2 {
  background-color: #fff;
  height: 30px !important;
}
.fi_active {
  font-weight: bold;
  color: #2196f3;
}

.font_title_1 {
  font-size: 30px;
  color: #4b6683;
  font-weight: bold;
}
.font_title_2 {
  font-size: 30px;
  color: #1b3857;
  font-weight: bold;
}

.bgcolor_no_information {
  background-color: #fff;
}

.color_gm_head {
  /*background-color:#FFFFFF;*/
}

.color_gm_head_1 {
  /*background-color:#DADADA;
color:#000000;*/
  /*height:30px;*/
  background: #a0ce96;
  background: #25852c -moz-linear-gradient(top, #438f20, #25852c) no-repeat;

  text-align: left;
  margin: 0;
  padding: 6px 6px;
}
.color_gm {
  /*background-color:#000000;*/
  background-color: #ebf6ff !important;
  height: 25px;
}
.color_gm_2 {
  background-color: #ffffff;
  height: 40px;
}
.color_gm_3 {
  background-color: #ffffff;
  height: 65px;
}

#nav-sub-meeting {
  margin: 0;
  padding: 0;
}
#nav-sub-meeting .here {
  font-weight: bold;
  color: #000;
}
#nav-sub-meeting li.sub-level-meeting {
  margin: 0px 0;
  list-style: none;
}
#nav-sub-meeting li.sub-level-meeting div {
  padding: 0px;
  cursor: pointer;
}
#nav-sub-meeting li.sub-level-meeting div:hover {
  text-decoration: none;
}
#nav-sub-meeting li.sub-level-meeting.sub-collpase ul {
  display: none;
  margin-bottom: 0px;
}
#nav-sub-meeting li.sub-level-meeting ul {
  margin: 0;
  padding: 0px 0;
  display: block;
}
#nav-sub-meeting li.sub-level-meeting ul li {
  text-align: left;
  vertical-align: top;
  list-style: none;
  padding-bottom: 0px;
  border: solid 0px #000;
}

#nav-sub-meeting li.sub-level-faqs ul li.help-item-last {
  border: none;
}
li.sub-level-meeting {
  background-color: #ebf6ff;
  border: solid 0px #000;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: hand;
}
#nav-sub-meeting ul {
  display: none;
}

a.treeview:link {
  color: #666666;
  font-weight: normal;
  /*font-size: 12px;*/
  text-decoration: none;
}
a.treeview:visited {
  color: #666666;
  font-weight: normal;
  /*font-size: 12px;*/
  text-decoration: none;
}
a.treeview:hover {
  color: #4b6683;
  font-weight: normal;
  /*font-size: 12px;*/
  text-decoration: none;
}
a.treeview:active {
  color: #666666;
  font-weight: normal;
  /*font-size: 12px;*/
  text-decoration: none;
}
a.treeviehead:link {
  color: #ffffff;
  font-weight: normal;
  /*font-size: 12px;*/
  text-decoration: none;
}
a.treeviehead:visited {
  color: #ffffff;
  font-weight: normal;
  /*font-size: 12px;*/
  text-decoration: none;
}
a.treeviehead:hover {
  color: #ffffff;
  font-weight: normal;
  /*font-size: 12px;*/
  text-decoration: none;
}
a.treeviehead:active {
  color: #ffffff;
  font-weight: normal;
  /*font-size: 12px;*/
  text-decoration: none;
}
.fonttreeview {
  color: #ffffff;
  font-weight: bold;
  /*font-size: 16px;*/
  text-decoration: none;
}
a .fonttreeview:hover {
  color: #4b6683;
  font-weight: bold;
  /*font-size: 12px;*/
  text-decoration: none;
}
.font_title_treeview_datetime {
  font-family: Tahoma;
  color: #4b6683;
  font-size: 11px;
  font-weight: bold;
}
.fonthead_meeting {
  /*FONT-SIZE: 12px; */
  position: relative;
  margin: 0;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #d8d8d8;
  padding: 5px 5px;
  /* Typography */

  color: #333333;
  text-decoration: none;
  text-shadow: 0 1px 1px #bebebe;
  letter-spacing: 0em;
  font-weight: 300;
  /* Background & effects */

  background: #ebebeb;
  opacity: 0.95;
}
.fonthead_meeting {
  color: #333333;
  font-weight: bold;
}
.fontsubhead_meeting {
  color: #333333;
  font-weight: normal;
}
.fontnolinkmeeting {
  color: #333333;
  font-weight: normal;
  padding-left: 32px;
}
.fontsubhead {
  color: #333333;
  font-weight: normal;
}
.clildmenu_visibled {
  display: none !important;
}

.font_title_treeview_datetime {
  font-family: Tahoma, Geneva, sans-serif;
  color: #fff;
  background: #4287af;
  text-indent: 30px;
  padding: 2px 2px 2px 2px;

  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;

  -moz-box-shadow: 0 1px 2px #d1d1d1;
  -webkit-box-shadow: 0 1px 2px #d1d1d1;
  box-shadow: 0 1px 2px #d1d1d1;
}

.fusion-builder-live
  .fusion-builder-live-toolbar
  .fusion-toolbar-nav
  > li.fusion-branding
  .fusion-builder-logo-wrapper
  .fusiona-avada-logo {
  background: url(https://www.jrw.co.th/wp-content/uploads/2019/11/JRW_LOGO.svg)
    no-repeat center !important;
  background-size: contain !important;
  width: 30px;
  height: 30px;
}

.fusion-builder-live
  .fusion-builder-live-toolbar
  .fusion-toolbar-nav
  > li.fusion-branding
  .fusion-builder-logo-wrapper
  .fusiona-avada-logo:before {
  display: none;
}

@media screen and (max-width: 640px) {
  body:not(.fusion-builder-ui-wireframe) .fusion-no-small-visibility {
    display: none !important;
  }
  body:not(.fusion-builder-ui-wireframe) .sm-text-align-center {
    text-align: center !important;
  }
  body:not(.fusion-builder-ui-wireframe) .sm-text-align-left {
    text-align: left !important;
  }
  body:not(.fusion-builder-ui-wireframe) .sm-text-align-right {
    text-align: right !important;
  }
  body:not(.fusion-builder-ui-wireframe) .sm-mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  body:not(.fusion-builder-ui-wireframe) .sm-ml-auto {
    margin-left: auto !important;
  }
  body:not(.fusion-builder-ui-wireframe) .sm-mr-auto {
    margin-right: auto !important;
  }
  body:not(.fusion-builder-ui-wireframe) .fusion-absolute-position-small {
    position: absolute;
    top: auto;
    width: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  body:not(.fusion-builder-ui-wireframe) .fusion-no-medium-visibility {
    display: none !important;
  }
  body:not(.fusion-builder-ui-wireframe) .md-text-align-center {
    text-align: center !important;
  }
  body:not(.fusion-builder-ui-wireframe) .md-text-align-left {
    text-align: left !important;
  }
  body:not(.fusion-builder-ui-wireframe) .md-text-align-right {
    text-align: right !important;
  }
  body:not(.fusion-builder-ui-wireframe) .md-mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  body:not(.fusion-builder-ui-wireframe) .md-ml-auto {
    margin-left: auto !important;
  }
  body:not(.fusion-builder-ui-wireframe) .md-mr-auto {
    margin-right: auto !important;
  }
  body:not(.fusion-builder-ui-wireframe) .fusion-absolute-position-medium {
    position: absolute;
    top: auto;
    width: 100%;
  }
}

@media screen and (min-width: 1025px) {
  body:not(.fusion-builder-ui-wireframe) .fusion-no-large-visibility {
    display: none !important;
  }
  body:not(.fusion-builder-ui-wireframe) .lg-text-align-center {
    text-align: center !important;
  }
  body:not(.fusion-builder-ui-wireframe) .lg-text-align-left {
    text-align: left !important;
  }
  body:not(.fusion-builder-ui-wireframe) .lg-text-align-right {
    text-align: right !important;
  }
  body:not(.fusion-builder-ui-wireframe) .lg-mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  body:not(.fusion-builder-ui-wireframe) .lg-ml-auto {
    margin-left: auto !important;
  }
  body:not(.fusion-builder-ui-wireframe) .lg-mr-auto {
    margin-right: auto !important;
  }
  body:not(.fusion-builder-ui-wireframe) .fusion-absolute-position-large {
    position: absolute;
    top: auto;
    width: 100%;
  }
}

.fusion-fullwidth.fusion-builder-row-1
  a:not(.fusion-button):not(.fusion-builder-module-control):not(
    .fusion-social-network-icon
  ):not(.fb-icon-element):not(.fusion-countdown-link):not(
    .fusion-rollover-link
  ):not(.fusion-rollover-gallery):not(.fusion-button-bar):not(
    .add_to_cart_button
  ):not(.show_details_button):not(.product_type_external):not(
    .fusion-view-cart
  ):not(.fusion-quick-view):not(.fusion-rollover-title-link):not(
    .fusion-breadcrumb-link
  ):hover,
.fusion-fullwidth.fusion-builder-row-1
  a:not(.fusion-button):not(.fusion-builder-module-control):not(
    .fusion-social-network-icon
  ):not(.fb-icon-element):not(.fusion-countdown-link):not(
    .fusion-rollover-link
  ):not(.fusion-rollover-gallery):not(.fusion-button-bar):not(
    .add_to_cart_button
  ):not(.show_details_button):not(.product_type_external):not(
    .fusion-view-cart
  ):not(.fusion-quick-view):not(.fusion-rollover-title-link):not(
    .fusion-breadcrumb-link
  ):hover:before,
.fusion-fullwidth.fusion-builder-row-1
  a:not(.fusion-button):not(.fusion-builder-module-control):not(
    .fusion-social-network-icon
  ):not(.fb-icon-element):not(.fusion-countdown-link):not(
    .fusion-rollover-link
  ):not(.fusion-rollover-gallery):not(.fusion-button-bar):not(
    .add_to_cart_button
  ):not(.show_details_button):not(.product_type_external):not(
    .fusion-view-cart
  ):not(.fusion-quick-view):not(.fusion-rollover-title-link):not(
    .fusion-breadcrumb-link
  ):hover:after {
  color: #03a9f4;
}

.fusion-fullwidth.fusion-builder-row-1 .pagination a.inactive:hover,
.fusion-fullwidth.fusion-builder-row-1
  .fusion-filters
  .fusion-filter.fusion-active
  a {
  border-color: #03a9f4;
}

.fusion-fullwidth.fusion-builder-row-1 .pagination .current {
  border-color: #03a9f4;
  background-color: #03a9f4;
}

.fusion-fullwidth.fusion-builder-row-1
  .fusion-filters
  .fusion-filter.fusion-active
  a,
.fusion-fullwidth.fusion-builder-row-1
  .fusion-date-and-formats
  .fusion-format-box,
.fusion-fullwidth.fusion-builder-row-1 .fusion-popover,
.fusion-fullwidth.fusion-builder-row-1 .tooltip-shortcode {
  color: #03a9f4;
}

#wrapper
  .fusion-fullwidth.fusion-builder-row-1
  .fusion-widget-area
  .fusion-vertical-menu-widget
  .menu
  li.current_page_ancestor
  > a,
#wrapper
  .fusion-fullwidth.fusion-builder-row-1
  .fusion-widget-area
  .fusion-vertical-menu-widget
  .menu
  li.current_page_ancestor
  > a:before,
#wrapper
  .fusion-fullwidth.fusion-builder-row-1
  .fusion-widget-area
  .fusion-vertical-menu-widget
  .current-menu-item
  > a,
#wrapper
  .fusion-fullwidth.fusion-builder-row-1
  .fusion-widget-area
  .fusion-vertical-menu-widget
  .current-menu-item
  > a:before,
#wrapper
  .fusion-fullwidth.fusion-builder-row-1
  .fusion-widget-area
  .fusion-vertical-menu-widget
  .current_page_item
  > a,
#wrapper
  .fusion-fullwidth.fusion-builder-row-1
  .fusion-widget-area
  .fusion-vertical-menu-widget
  .current_page_item
  > a:before {
  color: #03a9f4;
}

#wrapper
  .fusion-fullwidth.fusion-builder-row-1
  .fusion-widget-area
  .widget_nav_menu
  .menu
  li.current_page_ancestor
  > a,
#wrapper
  .fusion-fullwidth.fusion-builder-row-1
  .fusion-widget-area
  .widget_nav_menu
  .menu
  li.current_page_ancestor
  > a:before,
#wrapper
  .fusion-fullwidth.fusion-builder-row-1
  .fusion-widget-area
  .widget_nav_menu
  .current-menu-item
  > a,
#wrapper
  .fusion-fullwidth.fusion-builder-row-1
  .fusion-widget-area
  .widget_nav_menu
  .current-menu-item
  > a:before,
#wrapper
  .fusion-fullwidth.fusion-builder-row-1
  .fusion-widget-area
  .widget_nav_menu
  .current_page_item
  > a,
#wrapper
  .fusion-fullwidth.fusion-builder-row-1
  .fusion-widget-area
  .widget_nav_menu
  .current_page_item
  > a:before {
  color: #03a9f4;
}

#wrapper
  .fusion-fullwidth.fusion-builder-row-1
  .fusion-vertical-menu-widget
  .menu
  li.current_page_item
  > a {
  border-right-color: #03a9f4;
  border-left-color: #03a9f4;
}

#wrapper
  .fusion-fullwidth.fusion-builder-row-1
  .fusion-widget-area
  .tagcloud
  a:hover {
  color: #fff;
  background-color: #03a9f4;
  border-color: #03a9f4;
}

#main
  .fusion-fullwidth.fusion-builder-row-1
  .post
  .blog-shortcode-post-title
  a:hover {
  color: #03a9f4;
}
