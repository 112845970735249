.boxAtv {
  margin-top: 30px;
}

.boxAtv .box-1 {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 100px;
}

.box-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.cover-news {
  background-color: #ffffff;
  box-shadow: 0px 3px 20px 6px rgb(31 62 85 / 10%);
  border-radius: 6px;
  padding: 20px 20px 15px 20px;
}

.cover-news .news {
  border-bottom: 1px solid #e6e2eb;
  margin-bottom: 15px;
  padding-bottom: 13px;
}

.cover-news .news p {
  font-size: 18px;
  font-weight: normal;
  color: #666666;
  overflow: hidden;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 54px;
  margin-bottom: 10px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.cover-news .news .day {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  text-align: left;
}

.cover-news .news .time {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  text-align: right;
  margin-top: -20px;
}
