.chairman-pic {
  margin: auto;
  display: block;
  margin-bottom: 40px;
  margin-top: 30px;
}

.text-skn-standard.chairman-t1 {
  font-size: 20px;
  line-height: 1.6;
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: 40px;
  text-align: right;
  color: #1c3c93;
}

.text-skn-standard.chairman-t2 {
  font-size: 20px;
  text-align: right;
  font-weight: 300;
  margin-bottom: 0;
}
