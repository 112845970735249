@media screen and (min-width: 1881px) {
  .book-an-pic {
    position: absolute;
    bottom: 0;
    width: 29%;
  }
  .product-content-top .img-product img {
    width: 300px;
  }
  .container-banner.bg-image-product > .cover-content {
    max-width: 85%;
    margin: 120px auto 0;
  }
  .container-main {
    max-width: 65%;
  }
  .main-col {
    max-width: 50%;
  }
  .layout-fluid {
    max-width: 60%;
  }
  .content-footer {
    max-width: 60%;
  }
}

@media screen and (max-width: 1880px) {
  .content-footer {
    max-width: 70%;
  }
  .layout-fluid {
    max-width: 75%;
  }
  .main-col {
    max-width: 50%;
  }
  .container-main {
    max-width: 80%;
  }
  .container-banner.bg-image-product > .cover-content {
    margin-top: 10%;
  }
  .container-banner.bg-image-product p.sub-t-product {
    margin: 0 17% 40px;
  }
  .product-content-top .img-product img {
    width: 275px;
  }
  .container-form {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 751px, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 751px, 0 100%);
    height: 1151px;
  }
  .main-head-form {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .content-awards {
    max-width: 95%;
  }
  .text-award .t-1 {
    font-size: 1.563rem;
  }
  .text-award .t-2 {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 1720px) {
  /* .product-content-top .img-product img {
      width: 160px;
    } */
  .cover-head .head-raw {
    width: 80%;
  }
}

@media screen and (max-width: 1650px) {
  /* .container-main {
    max-width: 75%;
  } */
  .product-content-top .img-product img {
    width: 275px;
  }
  .left-t-detail .t-1 {
    font-size: 0.75rem;
  }
  .left-t-detail .t-2 {
    font-size: 0.75rem;
  }
  .right-t-detail .t-1 {
    font-size: 0.75rem;
  }
  .right-t-detail .t-2 {
    font-size: 0.75rem;
  }
  .head-raw {
    font-size: 2rem;
  }
  .text-award {
    padding: 90px 25px 70px 30px;
    width: 80%;
    height: 200px;
  }
  .pic-award {
    top: -90px;
  }
  .footer-link ul.sub a {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 1500px) {
  .container-main {
    max-width: 85%;
  }
  .product-content-top .img-product img {
    width: 240px;
  }
  .main-content-history {
    max-width: 90%;
  }
  .product-content-top .text-name-product .t-1-product {
    font-size: 1rem;
  }
  .product-content-top .text-name-product .t-2-product {
    font-size: 1.875rem;
  }
  .left-t-detail .t-1 {
    font-size: 0.625;
  }
  .left-t-detail .t-2 {
    font-size: 0.625;
  }
  .right-t-detail .t-1 {
    font-size: 0.625;
  }
  .right-t-detail .t-2 {
    font-size: 0.625;
  }
  .head-raw {
    font-size: 2rem;
  }
  p.sub-raw {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1400px) {
  .main-bt-email-contact {
    width: 65%;
  }
  .email-contact-bt {
    flex-direction: column;
  }
  figure.effect-action figcaption {
    top: auto;
    bottom: -25px;
    height: 50%;
    text-align: left;
    position: relative;
  }
  .main-ir-col .t-1 {
    font-size: 2.5rem;
  }
  .product-content-top .img-product img {
    width: 220px;
  }
  .t1-banner {
    font-size: 5.313rem;
  }
  .t2-banner {
    font-size: 2rem;
  }
  .bg-image-history {
    padding: 50px 0 360px 0;
  }
  .about-cover-t p {
    font-size: 1.375rem;
    padding: 200px 0 40px 25px;
  }
  .t1-product {
    font-size: 2rem;
  }
  .container-banner.bg-image-product p.sub-t-product {
    margin: 0 15% 40px;
  }
  .sub-cover {
    padding: 30px 20px 15px 25px;
  }
  .product-content-top .text-name-product .t-1-product {
    font-size: 0.938rem;
  }
  .product-content-top .text-name-product .t-2-product {
    font-size: 1.75rem;
  }
  .product-content-top .text-name-product .t-3-product {
    font-size: 0.813rem;
  }
  .head-raw {
    font-size: 1.875rem;
  }
  .cover-head .head-raw {
    width: 90%;
  }
  .cover-input-form {
    width: 70%;
  }
  .head-form {
    font-size: 2rem;
  }
  .book-an-text .t2 {
    font-size: 1rem;
  }
  /* .cover-head .sub-raw {
      padding-left: 33%;
    } */
}

@media screen and (max-width: 1279px) {
  .container-select-financial {
    gap: 0 !important;
  }
}
@media screen and (max-width: 1210px) {
  .content-awards {
    /* margin-left: auto; */
    margin-right: auto;
  }

  .nav-link,
  .dropdown-item {
    font-size: 1rem;
  }

  .nav-item {
    margin-left: 12px;
  }
  .col-66 {
    width: 100%;
    max-width: none;
  }
  .main-bt-email-contact {
    width: 100%;
  }
  .col-33 {
    max-width: 50%;
    width: 100%;
  }
  .email-contact-bt {
    flex-direction: row;
  }
  .layout-email {
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
  }
  .email-contact-text .t2 {
    margin-right: 0;
  }
  .main-ir-col .t-1 {
    font-size: 2.188rem;
  }
  .layout-our-ir {
    padding: 50px 20px 30px;
    height: 205px;
  }
  figure.effect-action h2 {
    font-size: 1.563rem;
  }
  figure.effect-action p {
    font-size: 1rem;
  }
  .container-main {
    max-width: 90%;
  }
  .container-banner.history-box {
    height: auto;
    min-height: min-content;
    background-position: left bottom;
    padding: 60px 15px 430px;
  }
  .container-banner {
    padding: 140px 9%;
    background-position: 80% center;
    height: 600px;
  }
  .t1-banner {
    font-size: 4.063rem;
    display: block;
    line-height: 0.9;
  }
  .t2-banner {
    font-size: 1.813rem;
    line-height: 1.2;
  }
  .t3-banner {
    width: 35%;
    font-size: 1.063rem;
    margin-top: 2rem;
  }
  .respon-none {
    display: none;
  }
  .bg-image-history {
    padding: 0 0 340px 0;
  }
  .main-content-history {
    max-width: 100%;
  }
  .about-text-cover .t-2-history {
    font-size: 1.875rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 15px;
  }
  .about-1-cover p {
    font-size: 1.75rem;
    padding: 437px 0 65px 60px;
  }
  .about-2-cover {
    margin-top: 90px;
  }
  .about-cover-t p {
    padding: 165px 0 40px 25px;
  }
  /* .container-banner.bg-image-product > .cover-content {
      margin-top: 295px;
    } */
  .t1-product {
    font-size: 1.875rem;
  }
  .container-banner.bg-image-product p.sub-t-product {
    margin: 0 0% 40px;
  }
  .sub-cover {
    margin-bottom: 30px;
  }
  .product-content-top .img-product img {
    width: 180px;
  }
  .content-product-box {
    gap: 1rem 0;
  }
  .cover-product {
    flex: none;
    max-width: 50%;
  }
  .product-content-top {
    margin: 0;
  }
  .head-raw {
    font-size: 1.25rem;
  }
  .text-name-product {
    text-align: left;
  }
  .cover-head .head-raw {
    width: 100%;
  }
  .container-form {
    -webkit-clip-path: polygon(0 0vh, 100% 0, 100% 521px, 0 100%);
    clip-path: polygon(0 0vh, 100% 0, 100% 521px, 0 100%);
    height: 926px;
    margin-top: 90px;
  }
  .cover-input-form {
    width: 73%;
  }

  .input-form {
    font-size: 18px;
    height: 45px;
    margin-bottom: 15px;
  }
  .textarea-form {
    height: 90px;
  }
  .head-form {
    font-size: 1.875rem;
  }
  .cover-form {
    padding-top: 80px;
  }
  .text-award {
    padding: 125px 25px 70px 30px;
    width: 100%;
    height: 230px;
  }
  .pic-award {
    right: 0;
  }
  .text-award .t-1 {
    font-size: 1.438rem;
  }
  .text-award .t-2 {
    font-size: 1.25rem;
  }
  .main-content-product {
    max-width: 85%;
  }
  .container-content-ir {
    background-position: 32% top;
    background-size: cover;
  }
  .col-25 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-75 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cover-download-doc {
    padding: 20px 20px 20px 20px;
    margin-bottom: 2rem;
    height: auto;
  }
  .main-col-kits {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
  }
  .book-an-pic {
    width: 24%;
  }
  .footer-link ul.sub a {
    font-size: 0.75rem;
  }
  .margin-button {
    margin-bottom: 0;
  }
  /* .col-50 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  } */
  /* .cover-head .sub-raw {
      padding-left: 37%;
    } */

  .select-year-fn {
    margin-right: 20px;
  }
}

@media screen and (max-width: 1050px) {
  .nav-link,
  .dropdown-item {
    font-size: 15px;
  }
  .col-50 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container-select-financial {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 50px !important;
    width: 70% !important;
  }
}

@media screen and (max-width: 991px) {
  .container-top {
    margin-top: 110px;
  }
  .layout-text-head {
    margin: 40px 0;
  }
  a.dropdown-item.padding-left {
    padding-left: 2rem;
  }
  li:hover > ul.dropdown-item-sub.left {
    min-width: 10rem;
    margin: 0;
    padding: 0;
    font-size: 0.9375rem;
    color: #fff;
    text-align: left;
    list-style: none;
    background-color: none;
    position: static;
    float: none;
    box-shadow: none;
  }
  .footer-link ul.sub a {
    font-size: 1rem;
  }
  .main-footer-link {
    padding-left: 0;
  }
  .col-25-footer {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-50-footer {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 2rem 0 0;
  }

  .layout-fluid {
    max-width: none;
    justify-content: center;
  }

  .nav-link,
  .dropdown-item {
    font-size: 1.125rem;
  }

  .collapse:not(.show) {
    display: none;
  }

  .navbar-hamburger {
    display: block;
  }

  .navbar-mobile {
    flex-basis: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    gap: 5px;
  }

  li.nav-item > ul.dropdown-menu {
    top: 36.5px;
    left: 0;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0;
    margin: 0;
    font-size: 0.9375rem;
    color: #fff;
    text-align: left;
    list-style: none;
    background-color: none;
    position: static;
    float: none;
    box-shadow: none;
  }

  .col-33 {
    max-width: 65%;
  }
  .t1.add-margin {
    margin: 3.5rem 0;
  }
  .container-content-financial {
    height: auto;
  }
  .cover-kits.cover-kits-1,
  .cover-kits.cover-kits-2,
  .cover-kits.cover-kits-3,
  .cover-kits.cover-kits-4 {
    height: auto;
  }
  .cover-kits .t1 br {
    display: none;
  }
  .main-col-box-ir {
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 0;
  }
  .main-col-kits {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .cover-stock-price {
    gap: 3rem;
  }
  .main-ir-col {
    max-width: none;
    flex: 0 0 100%;
  }
  .main-col {
    max-width: none;
  }
  .container-main {
    max-width: 80%;
  }
  .cover-banner-other-content {
    margin-top: 80px;
    margin-bottom: 50px;
  }
  .container-banner {
    padding: 140px 15px;
    text-align: center;
  }
  .bg-image-banner {
    background: url(../assets/Bg/banner-homepage-ipad.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }
  .bg-image-banner-ir {
    background: url(../assets/Bg/ir-banner-f-2.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }
  p {
    margin-bottom: 1rem;
  }
  .t1-banner {
    font-size: 4.063rem;
    display: inline-block;
    line-height: 0.9;
    margin-top: 1rem;
  }
  .t2-banner {
    font-size: 1.813rem;
    line-height: unset;
  }
  .t3-banner {
    width: auto;
    margin: auto;
    max-width: 450px;
  }
  .bg-image-history {
    padding: 0 0 340px 0;
  }
  .hide {
    display: contents;
  }
  .history-homepage .about-text-cover {
    padding: 10px 12% 20px 5%;
    margin: 40px 0;
    text-align: left;
  }
  .about-1-cover p {
    font-size: 1.5rem;
    padding: 300px 0 35px 35px;
    text-align: left;
  }
  .hide-2 {
    display: none;
  }
  .about-2-cover {
    margin-top: 80px;
  }
  .about-cover-t p {
    padding: 130px 0 30px 25px;
    font-size: 1.375rem;
    text-align: left;
  }
  .product-content-top .text-name-product .t-1-product {
    font-size: 1rem;
  }
  .product-content-top .text-name-product .t-2-product {
    font-size: 1.875rem;
  }
  .product-content-top .text-name-product .t-3-product {
    font-size: 0.875rem;
  }
  .left-t-detail .t-1 {
    font-size: 0.875rem;
  }
  .left-t-detail .t-2 {
    font-size: 0.875rem;
  }
  .right-t-detail .t-1 {
    font-size: 0.875rem;
  }
  .right-t-detail .t-2 {
    font-size: 0.875rem;
  }
  .cover-product {
    flex: none;
    max-width: 100%;
  }
  .container-banner.bg-image-product {
    -webkit-clip-path: polygon(0 291px, 100% 0, 100% 1571px, 0 100%);
    clip-path: polygon(0 291px, 100% 0, 100% 1571px, 0 100%);
    height: 1856px;
  }
  /* .container-banner.bg-image-product > .cover-content {
      margin-top: 200px;
    } */
  .container-raw .cover-head {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .cover-head .head-raw {
    float: none;
    margin-right: 0;
    text-align: left;
    margin-bottom: 8px;
  }
  .cover-head .sub-raw {
    padding-left: 0;
    font-size: 1.188rem;
    text-align: left;
  }
  .head-raw {
    font-size: 1.875rem;
  }
  .container-form {
    -webkit-clip-path: polygon(0 0vh, 100% 0, 100% 70vh, 0 100%);
    clip-path: polygon(0 0vh, 100% 0, 100% 641px, 0 100%);
  }
  .cover-input-form {
    width: 70%;
  }
  .text-award {
    padding: 122px 25px 0 30px;
    height: 225px;
    text-align: center;
  }
  .pic-award {
    position: absolute;
    top: -70px;
    right: 0;
    left: 0;
    margin-right: 0;
    text-align: center;
    width: 200px;
    margin: auto;
  }
  .pic-award img {
    width: 100%;
  }
  .text-award .t-1 {
    font-size: 1.375rem;
  }
  .text-award .t-2 {
    font-size: 1.125rem;
  }
  .main-content-product {
    max-width: 90%;
  }
  .content-row-financial {
    gap: 3rem;
  }

  .container-select-financial {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 35px !important;
  }
  .content-stock-info {
    flex-direction: column;
    gap: 3rem;
    box-shadow: none;
  }
  .stock-info-right,
  .stock-info-left {
    width: 100%;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    border-radius: 24px;
  }
  .content-about-info {
    align-items: center !important;
  }
}

@media screen and (max-width: 979px) {
  .container-select-financial {
    gap: 35px !important;
    width: 80% !important;
  }
}

@media screen and (max-width: 770px) {
  .layout-text-head .t1 {
    font-size: 1.188rem;
  }
  .layout-text-head .t2 {
    font-size: 2.188rem;
  }
  .content-footer {
    max-width: 80%;
  }
  .footer-about-text p {
    font-size: 1rem;
  }
  .text-fluid,
  .text-fluid a.a-cookies-policy {
    font-size: 0.75rem;
    text-align: center;
  }

  .main-box-chart {
    margin: 10px 5% 0px;
  }
  .col-33 {
    max-width: 75%;
  }
  .book-an-text .t2 {
    text-align: center;
  }
  .main-book-an-pic {
    display: flex;
    justify-content: center;
  }
  .book-an-pic {
    width: 35%;
  }
  .cover-kits.cover-kits-5 {
    height: auto;
  }
  .cover-kits .book-an-text {
    padding: 15px 5% 61% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cover-banner-other-content {
    margin-top: 0;
    margin-bottom: 50px;
  }
  .bg-image-history {
    padding: 0 0 400px 0;
  }
  .main-content-history {
    max-width: 80%;
  }
  .history-homepage .about-text-cover {
    margin: 30px 0;
  }
  .about-text-cover .t-1-history {
    font-size: 1.188rem;
    line-height: 0;
    font-weight: 600;
    color: var(--clr-gray);
    margin: 10px 0 30px 0;
  }
  .about-text-cover .t-2-history {
    font-size: 2.188rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 15px;
  }
  .about-1-cover p {
    font-size: 1.5rem;
    padding: 270px 0 35px 35px;
    text-align: left;
  }
  .about-2-cover {
    margin-top: 30px;
  }
  .history-homepage {
    max-width: none;
  }
  .container-banner.bg-image-product > .cover-content {
    margin-top: 60px;
  }
  .t1-product {
    font-size: 1.875rem;
  }
  .container-banner.bg-image-product p.sub-t-product {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 25px;
    padding-bottom: 15px;
  }
  .cover-head .head-raw {
    margin-bottom: 0;
  }
  .container-form {
    -webkit-clip-path: polygon(0 0vh, 100% 0, 100% 751px, 0 100%);
    clip-path: polygon(0 0vh, 100% 0, 100% 751px, 0 100%);
  }
  .cover-input-form {
    width: 70%;
  }
  .head-form {
    font-size: 1.563rem;
  }
  .cover-form {
    padding-top: 55px;
  }
  .content-awards-layout {
    flex-direction: column;
  }
  .main-head-reward {
    max-width: 100%;
  }
  .text-award .t-1 {
    padding-top: 0 !important;
  }
  .main-content-product {
    max-width: 85%;
  }
  .container-select-financial {
    gap: 20px !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 575px) {
  .cover-bt-report {
    max-width: 50%;
  }
  .cover-bg-content-report .t2 {
    font-size: 1rem;
  }
  .footer-link ul.sub {
    padding-left: 5px;
  }
  .main-footer-contact {
    padding-left: 0;
    margin: 3rem 0 2rem;
  }
  .col-25-footer {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-33 {
    max-width: none;
  }
  .main-bt-email-contact {
    width: 65%;
  }
  .email-contact-bt {
    flex-direction: column;
  }
  .container-main {
    max-width: 95%;
  }
  .cover-banner-other-content {
    margin-bottom: 50px;
  }
  .container-banner {
    padding: 120px 15px;
  }
  .bg-image-banner {
    background: url(../assets/Bg/banner-homepage-ipad.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }
  .bg-image-banner-ir {
    background: url(../assets/Bg/ir-banner-f-3.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;
  }
  p {
    margin-bottom: 1rem;
  }
  .t1-banner {
    font-size: 4.063rem;
    display: inline-block;
    line-height: 0.9;
  }
  .t2-banner {
    font-size: 1.813rem;
    line-height: unset;
  }
  .t3-banner {
    width: auto;
    margin: auto;
    max-width: 450px;
  }
  .main-content-history {
    max-width: 100%;
  }
  .head-raw {
    font-size: 1.5rem;
  }
  .cover-input-form {
    width: 90%;
  }
  .main-content-product {
    max-width: 95%;
  }
  .navigation-wrap {
    width: 94% !important;
  }
  .hexagon {
    height: 280px !important;
  }
  .btn-fn {
    height: 40px !important;
    font-size: 14px !important;
  }
}

@media screen and (max-width: 490px) {
  .dropdown-item {
    white-space: break-spaces;
  }
  .cover-bt-report {
    max-width: 60%;
  }
  .layout-text-head .t1 {
    font-size: 1.125rem;
  }
  .layout-text-head .t2 {
    font-size: 2rem;
  }
  figure.effect-action p {
    font-size: 0.875rem;
  }
  .content-footer {
    max-width: 90%;
  }
  .product-content-top .img-product {
    display: flex;
    justify-content: center;
  }
  .input-form.textarea-form {
    height: 90px;
  }
  .main-bt-email-contact {
    min-width: 50%;
  }
  .cover-kits .t1 {
    font-size: 1.5rem;
  }
  .cover-banner-other-content {
    margin-bottom: 50px;
    margin-top: 0;
  }
  .bg-image-banner-ir {
    background: url(../assets/Bg/ir-banner-f-4.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 700px;
  }
  .about-text-cover .t-1-history {
    font-size: 1.125rem;
    margin: 10px 0 27px 0;
  }
  .bg-image-banner {
    /* min-height: 850px; */
    /* min-height: 100%; */
    /* background-size: contain; */
    background-position: center bottom;
  }
  .t1-banner {
    font-size: 3.438rem;
    margin: 1rem 0 0;
  }
  .t1-banner.thin {
    margin: 0;
  }
  .t2-banner {
    font-size: 1.5rem;
  }
  .left-t-detail .t-1 {
    font-size: 1rem;
  }
  .product-content-top {
    display: flex;
    flex-direction: column;
  }
  .product-content-bottom {
    display: flex;
    gap: 2.5rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
  }
  .right-t-detail {
    text-align: center;
  }
  .container-banner.bg-image-product {
    clip-path: polygon(0 120px, 100% 0, 100% 2200px, 0 100%);
    height: 2300px;
  }
  .container-banner.bg-image-product > .cover-content {
    /* margin-top: 95px; */
    margin-top: -50px;
  }
  .text-name-product {
    text-align: center;
  }
  .container-form {
    -webkit-clip-path: polygon(0 0vh, 100% 0, 100% 831px, 0 100%);
    clip-path: polygon(0 0vh, 100% 0, 100% 791px, 0 100%);
  }
  .input-form {
    font-size: 17px;
    height: 42px;
    margin-bottom: 15px;
    padding-left: 12px;
  }
  .content-awards {
    max-width: 100%;
  }
  .box-award {
    padding: 0;
  }
  figure.effect-action figcaption {
    text-align: center;
  }
  .layout-our-ir {
    /* padding: 30px 20px; */
    height: 260px;
  }
  /* .container-select-financial {
    gap: 2px !important;
  } */

  .container-select-financial {
    grid-template-columns: repeat(1, 1fr) !important;
    width: 55% !important;
    gap: 35px !important;
  }
  .hexagon {
    height: 270px !important;
  }
  .t-s {
    font-size: 14px;
  }
  .t-xl {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 420px) {
  .container-select-financial {
    width: 60% !important;
    font-size: 15px !important;
  }
}
@media screen and (max-width: 400px) {
  .container-select-financial {
    width: 65% !important;
  }
  .sharesInfo {
    font-size: 32px;
  }
  .t-xl {
    font-size: 20px;
  }
}
@media screen and (max-width: 375px) {
  .text-not-data {
    font-size: 1rem;
  }
  .layout-text-head .t1 {
    font-size: 1rem;
  }
  .layout-text-head .t2 {
    font-size: 1.563rem;
  }
  /* figure.effect-action figcaption {
    text-align: center;
  }
  .layout-our-ir {
    padding: 30px 20px;
    height: 260px;
  } */
  .cover-kits .t1 {
    font-size: 1.25rem;
  }
  /* .bg-image-banner {
      min-height: 850px;
    } */
  .t1-banner {
    font-size: 3.438rem;
  }
  .t2-banner {
    font-size: 1.5rem;
  }
  .select-year p {
    margin-right: 10px !important;
  }
}

@media screen and (max-width: 375px) and (max-height: 667px) {
  .bg-image-banner {
    background-size: contain;
  }
  .t3-banner,
  .about-text-cover p,
  .sub-t-product,
  .cover-head .sub-raw,
  .input-form,
  .accept-terms,
  .text-award .t-2 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 280px) and (max-height: 653px) {
  .text-award {
    padding: 95px 25px 0 30px;
    height: 240px;
  }
  .pic-award {
    width: 160px;
  }
  .layout-text-head .t1 {
    font-size: 0.8rem;
  }
}
