.cover-board {
  display: flex;
  flex-wrap: wrap;
  margin-right: 150px;
  margin-left: 150px;
  justify-content: center;
  margin-top: 60px;
}

.box-content {
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  margin-left: 20px;
  gap: 1rem;
  font-size: 20px;
}

.box-content p {
  color: #a5a5a5;
}

.box-content-1 {
  flex: 1 1;
  gap: 2rem;
  margin: 30px;
  width: 50%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}
.pic-content {
  display: flex;
  justify-content: center;
}
.text-r2 {
  margin: 2.2rem 0;
}
.dateLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.textLink {
  color: var(--clr-blue);
  text-decoration: none;
}
