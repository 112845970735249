.container-banner {
  display: block;
  vertical-align: middle;
  width: 100%;
  padding: 15% 10%;
}

.bg-image-banner {
  background: url(../assets/Bg/banner-homepage.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

p {
  font-size: 1.25rem;
  /* line-height: 1.6; */
  font-weight: 400;
  color: #000;
}

/* span {
  font-family: var(--f-db);
  font-size: 5.938rem;
  font-weight: 200;
} */

.t0-banner {
  font-size: 1.563rem;
  font-weight: 600;
  color: var(--clr-blue);
  margin-bottom: -10px;
}

.t1-banner {
  font-size: 5.313rem;
  font-family: var(--f-db);
  color: var(--clr-blue);
  font-weight: 700;
}

.t1-banner.thin {
  font-weight: 200;
  color: var(--clr-black);
  /* margin: 0 1rem; */
}

.t2-banner {
  font-size: 2.125rem;
  font-weight: 700;
  font-family: var(--f-db-b);
  line-height: 0.2;
}

.t3-banner {
  font-size: 1.125rem;
  line-height: 1.6;
  font-weight: 400;
  color: var(--clr-black-gray);
  width: 44%;
  margin-top: 2.5rem;
}

.bg-image-history {
  background: url(../assets/Bg/bg-history-homepage.png);
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: auto;
  padding: 50px 0 330px 0;
}

.container-banner.history-box {
  height: auto;
  min-height: min-content;
}

.main-content-history {
  max-width: 70%; /*90%*/
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.content-history {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: space-around;
}

.history-homepage {
  position: relative;
  width: 100%;
  max-width: 49%;
  padding-right: 15px;
  padding-left: 15px;
}

.hide {
  display: none;
}

.history-homepage .about-text-cover {
  border-right: 1px solid var(--line-clr-gray);
  border-left: 1px solid var(--line-clr-gray);
  padding: 10px 9%;
  margin: 40px 0 0;
}

.about-text-cover .t-1-history {
  font-size: 1.5rem;
  line-height: 0;
  font-weight: 600;
  color: var(--clr-gray);
  margin: 10px 0 55px 0;
}

.about-text-cover .t-2-history {
  font-size: 2.1rem;
  line-height: 0;
  font-weight: 800;
  margin-bottom: 40px;
  color: var(--clr-black);
}

.about-1-cover {
  background: url(../assets//Image/card-history-1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  transition: ease 0.5s;
}

.about-1-cover:hover,
.about-2-cover:hover,
.about-3-cover:hover {
  opacity: 0.8;
}

.about-1-cover p {
  color: var(--clr-white);
  font-size: 30px;
  font-weight: 800;
  line-height: 1.3;
  font-style: normal;
  padding: 80% 0 65px 80px;
}

.about-2-cover {
  background: url(../assets/Image/card-history-2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  transition: ease 0.5s;
  margin-top: 130px;
}

.about-cover-t p {
  color: var(--clr-white);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
  font-style: normal;
  padding: 75% 0 40px 30px;
}

.about-3-cover {
  background: url(../assets/Image/card-history-3.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  transition: ease 0.5s;
  margin-top: 40px;
}

a,
a:hover,
a:visited,
a:active,
a:link {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

.container-banner.bg-image-product {
  position: relative;
  -webkit-clip-path: polygon(0 412px, 100% 0, 100% 1111px, 0 100%);
  clip-path: polygon(0 412px, 100% 0, 100% 1200px, 0 100%);
  height: 1531px; /*1531px*/
  margin-top: -380px;
  background: url(../assets//Bg/bg-product-homepage.png);
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  padding: 290px 0;
}

.container-banner.bg-image-product > .cover-content {
  position: relative;
  width: 100%;
  margin-top: 10%;
}

.main-content-product {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.content-product {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: space-around;
  flex-direction: column;
}

.content-product-box {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.t1-product {
  text-align: center;
  font-size: 2.125rem;
  font-weight: 700;
  color: var(--clr-white);
  margin-bottom: 1rem;
}

.container-banner.bg-image-product p.sub-t-product {
  text-align: center;
  border-right: 1px solid var(--clr-white);
  border-left: 1px solid var(--clr-white);
  margin: 0 24%;
  margin-bottom: 50px;
  color: var(--clr-white);
  padding: 10px 2rem 10px;
}

.cover-product {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.product-content-top {
  /* display: flex;
  margin-bottom: 2rem; */

  display: inline-block;
  margin-bottom: 2rem;
  width: 100%;
}

.product-content-top .text-name-product {
  float: left;
}

.product-content-top .text-name-product .t-1-product {
  font-size: 1.125rem;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 20px;
}

.product-content-top .text-name-product .t-2-product {
  font-size: 2.125rem;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 5px;
}

.product-content-top .text-name-product .t-3-product {
  font-size: 1rem;
  font-weight: 400;
}

.product-content-top .img-product {
  float: right;
}

.product-content-top .img-product img {
  margin-top: 10px;
}

.product-content-bottom {
  /* display: inline-block;
  width: 100%; */

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
}

.left-t-detail {
  float: left;
}

.left-t-detail .t-1 {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: -25px;
}

.left-t-detail .t-2 {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0;
}

.right-t-detail {
  float: right;
  text-align: right;
}

.right-t-detail .t-1 {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: -25px;
}

.right-t-detail .t-2 {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0;
}

.sub-cover {
  background-color: rgb(214 214 214/91%);
  border-radius: 15px;
  padding: 40px 30px 25px 35px;
  height: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: space-between;
}

.container-banner.container-raw {
  background: url(../assets/Bg/bg-MDF-board-features.png);
  background-position: right 20px;
  background-repeat: no-repeat;
  background-size: auto;
  margin-top: -310px;
  padding-top: 340px;
  height: 100%;
  /* min-height: 100vh; */
}

.content-raw {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.main-content-raw-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* margin-right: -15px; */
  margin-left: -15px;
}

.content-raw {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.container-raw .cover-head {
  margin-bottom: 90px;
  display: flex;
  justify-content: center;
}

.cover-head .head-raw {
  float: left;
  margin-right: 40px;
  margin-bottom: 20px;
  width: 60%;
}

.head-raw {
  text-align: center;
  font-size: 2.125rem;
  font-weight: 700;
}

.cover-head .sub-raw {
  position: relative;
  top: 15px;
}

.img-raw {
  width: 100%;
  position: relative;
  margin: auto;
}

.img-raw img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.container-form {
  background: url(../assets/Bg/bg-form-quotation.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 771px, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 771px, 0 100%);
  height: 1211px;
  /* margin-top: 130px; */
}

.content-form {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.cover-form {
  padding-top: 120px;
}

.main-head-form {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.head-form {
  text-align: center;
  font-size: 2.125rem;
  font-weight: 700;
  color: var(--clr-brown);
}

.cover-input-form {
  width: 50%;
  margin: 35px auto 0;
}

.input-form {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #6e4d25;
  font-size: 17px;
  font-weight: 300;
  color: #6e4d25;
  height: 55px;
  margin-bottom: 20px;
  padding-left: 20px;
  background-color: unset;
  font-size: 20px;
  font-weight: 400;
}

.input-form::placeholder {
  color: #6e4d25;
}

.textarea-form {
  height: 90px;
  padding-top: 12px;
  border: 1px solid #6e4d25;
}

.textarea-form::placeholder {
  color: #6e4d25;
}

.accept-terms {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: prompt, sans-serif;
  color: #6e4d25;
  background-color: rgb(255 255 255/0%);
  border: 0 solid #ffffff30;
  border-radius: 0;
  margin-top: -10px;
  padding-top: 4px;
  font-size: 18px;
  font-weight: 400;
}

label.accept-terms a {
  color: #946b3b;
  transition: 0.25s;
  margin: 0 5px;
}

.accept-terms input:checked ~ .checkmark:after {
  display: block;
}

.accept-terms .checkmark:after {
  left: 5px;
  top: -1px;
  width: 6px;
  height: 14px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.accept-terms input:checked ~ .checkmark {
  background-color: #6e4d25;
}

.accept-terms input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #6e4d25;
  border-radius: 3px;
  margin-top: 6px;
  transition: 0.2s;
}

[type="button"].bt-submit {
  background-color: #6e4d25;
  border: 0 solid;
  border-radius: 15px;
  width: 50%;
  margin: auto;
  display: block;
  margin-top: 2rem;
  color: white;
  font-size: 1.2rem;
  padding: 15px 0px 15px 0px;
  cursor: pointer;
}

.container-awards {
  margin-bottom: 120px;
}

.content-awards {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  /* margin-right: auto; */
  margin-left: auto;
  max-width: 95%;
}

.content-awards-layout {
  display: flex;
  flex-wrap: wrap;
  /* margin-right: -15px;
  margin-left: -15px; */
  width: 100%;
  justify-content: center;
}

.t-black {
  color: var(--clr-black);
}

.box-award {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.cover-award {
  margin-top: 130px;
  position: relative;
}

.text-award {
  background-color: #e2e2e2;
  padding: 50px 45px 50px 40px;
  width: 85%;
  border-radius: 15px;
  height: 185px;
}

.text-award .t-1 {
  font-size: 1.875rem;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 10px;
}

.text-award .t-2 {
  font-size: 1.313rem;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 15px;
}
.pic-award {
  position: absolute;
  top: -70px;
  right: 10%;
  margin-right: 20px;
  border: 1.5px solid #e2e2e2;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  background: #fff;
}

.box-card-award {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.main-head-reward {
  position: relative;
  width: 100%;
  /* padding-right: 15px;
  padding-left: 15px; */
}

/* .product-homepage {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
} */

/* @media screen and (min-width: 2160px) {
  .container-banner.history-box {
    height: auto;
    min-height: min-content;
    background-position: left bottom;
    padding: 60px 22% 430px;
  }
} */

/* @media screen and (min-width: 1200px) {
  .content-raw {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .main-head-form {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
} */

/*--------------------- IR Page ----------------------------*/
/* .bg-image-banner-ir {
  background: url(../../../assets/Bg/ir-banner-f.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
} */
