.icon-pdf svg {
  fill: var(--clr-blue);
  margin: auto;
  width: 25px;
  height: 25px;
}
.select-year {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.select-year p {
  margin-right: 30px;
}

.select-1 {
  color: #a5a5a5;
}

.select-box {
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  height: calc(2.15625rem + 16px);
  width: 100%;
  max-width: 200px;
}
.calendar-tb-1 {
  width: 20%;
}
.calendar-tb-2 {
  width: 30%;
}
.calendar-tb-3 {
  width: 30%;
}
.calendar-tb-4 {
  width: 20%;
}

.p-number {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-top: 5em;
  padding-bottom: 3em;
  gap: 2px;
}

.border-cal {
  border: 1px solid;
  padding: 2px;
  width: 30px;
  height: 50;
  border-color: var(--clr-gray);
  color: var(--clr-darkgray);
}
