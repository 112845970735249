.vision-pic {
  width: 240px;
  display: block;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 50px;
}

.vision-pic-1 {
  width: 140px;
  display: block;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 50px;
}
.vision-pic-icon {
  width: 100px;
  display: block;
  margin: auto;
}
.text-skn-standard-vision {
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
  text-align: center;
}

.icon-box-vision {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: 30px;
}

.icon-box-vision-g {
  flex: 1;
  align-items: center;
  margin: auto;
  text-align: center;
}

.bg-vision-sec2 {
  background: url(../../assets/Bg/bg-mission-1.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
