.bg-awd {
  background: url(../../assets/Bg/bg-awards.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 3rem 0 0;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 3rem;
}

.box-awd {
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  margin-left: 20px;
  justify-content: center;
}

.box-awd-1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: 30px;
  width: 70%;
}

.box-awd-1-1 {
  flex: 1;
  align-items: center;
  text-align: center;
  /* margin-top: 20px; */
  position: relative;
  padding: 45px 25px 50px 30px;
}

.box-awd-1-1 .sub {
  background-color: #f8fdfe;
  border-radius: 15px;
  height: 130px;
  position: relative;
  width: 400px;
}

.text-awd {
  text-align: left;
  padding: 30px 25px 30px 30px;
}

.text-awd .t1 {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.text-awd .t2 {
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.img-awd {
  display: block;
  margin: auto;
  position: absolute;
  top: -50px;
  right: -70px;
  border: 1.5px solid #e2e2e2;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  background: #fff;
  width: 45%;
}
