.cover-map {
  position: relative;
}

.contact-icon {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin-top: 30px;
  margin-bottom: 30px;
}

.cover-pic-text-contact {
  display: flex;
  align-items: start;
  gap: 20px;
}

.cover-pic-contact {
  width: 50px;
}

.cover-pic-contact img {
  width: 100%;
}

.contact-t1 {
  font-weight: 700;
  color: #1c3c93;
}

.contact-cover {
  width: 100%;
  position: relative;
}

.form-contact form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-contact input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #bababa;
  margin-bottom: 20px;
  padding-left: 20px;
  font-size: 14px;
  height: 55px;
  font-weight: 400;
}

.form-contact input[type="text"]:focus,
.form-contact input[type="text"]:active {
  transition: all 0.3s;
  border-color: transparent;
  border: 1px solid #1c87c9;
}

.form-contact textarea:focus,
.form-contact textarea:active {
  transition: all 0.3s;
  border-color: transparent;
  border: 1px solid #1c87c9;
}

.form-contact textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #bababa;
  margin-bottom: 20px;
  padding-left: 20px;
  font-size: 14px;
  height: 80px;
  font-weight: 400;
  padding-top: 10px;
}

.form-contact .text-area-inquiry {
  height: 200px;
}

.btn-blue {
  font-size: 17px;
  padding: 13px 30px;
  margin: 5px 0 10px 0;
  background-color: #1c3a91;
  width: 200px;
  color: #fff;
}

.contant-btn {
  margin-bottom: 80px;
}

.check-inqurity {
  width: 30px;
}

.contact-dot {
  position: absolute;
  bottom: 12px;
  left: -230px;
  height: 180px;
  width: 180px;
  background-color: rgb(204 204 204 / 65%);
  border-radius: 100px;
  z-index: -20;
}

.contact-dot-4 {
  position: absolute;
  top: -46px;
  left: -38px;
  height: 80px;
  width: 80px;
  background-color: #d1d8e9;
  border-radius: 100px;
  z-index: -20;
}

.contact-dot-5 {
  position: absolute;
  top: 0px;
  right: -63px;
  height: 210px;
  width: 210px;
  background-color: #d1d8e9;
  border-radius: 100px;
  z-index: -20;
}

@media (max-width: 1280px) {
  .contact-icon {
    row-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));
  }
}
