p {
  color: #666;
}

a {
  color: #1b3a91;
}

.row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: 50px;
  margin-left: 50px;
  /* max-width: 100%; */
}

.contactIR-logo {
  display: flex;
  margin-top: 50px;
  margin-bottom: 60px;
  /* margin-left: 80px; */
}

.contactIR-logo-EN {
  display: flex;
  margin-top: 50px;
  margin-bottom: 60px;
  margin-left: 130px;
}

.contactIR-dot {
  position: absolute;
  top: 300px;
  right: -0px;
  opacity: 0.5;
  z-index: -1;
}
.contactIR-t1 {
  text-align: center;
  /* font-size: 18px; */
}

@media (min-width: 375px) {
  .contactIR-logo {
    margin-left: 0px;
  }
  .contactIR-logo-EN {
    margin-left: 0px;
  }
}

@media (min-width: 425px) {
  .contactIR-logo {
    margin-left: 15px;
  }
  .contactIR-logo-EN {
    margin-left: 20px;
  }
}

@media (min-width: 768px) {
  .contactIR-logo {
    margin-left: 100px;
  }
  .contactIR-logo-EN {
    margin-left: 130px;
  }
}
